import React from "react";
import { createContext, useContext } from 'react';
import Axios from "axios";

const KeycloakContext = createContext();

const setAuthForAxios = (token) => {
    Axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    });
}

const setForbiddenHandlerForAxios = (keycloak) => {
    //axios.interceptors.response.use(null, function (error) {
    //    if (error.status === 403) {
    //        keycloak.login({ redirectUri: '/redirect-debug-from-keycloakContext' });
    //    }
    //    return Promise.reject(error);
    //});

    Axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.status === 403) {
            keycloak.login({ redirectUri: '/redirect-debug-from-keycloakContext' });
        }
        return Promise.reject(error);
    });
}

export const KeycloakProvider = ({
    children,
    keycloak,
    loading = (<p>Loading...</p>)
}) => {

    const [auth, setAuth] = React.useState({ authenticated: false, keycloak: null, initialized: false, profile: null })

    setForbiddenHandlerForAxios();

    const refreshToken = React.useCallback(() => {
        setTimeout(() => {
            if (auth.authenticated === true && keycloak.authenticated === false) {
                console.log("User was logged in. Changing SPA state to logged out.")
                setAuth({ keycloak: null, authenticated: false, initialized: true, profile: null })
                return;
            }

            if (keycloak.authenticated === false) {
                console.log("Not authenticated - no refreshing needed.")
                return;
            }

            keycloak.updateToken(70).then((refreshed) => {

                if (refreshed === true) {
                    console.info('Token refreshed ' + refreshed);
                    setAuthForAxios(auth.keycloak.token);
                } else {
                    //console.warn('No refresh. Valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                }
                refreshToken()
            }).catch((err) => {

                if (err === true) {
                    console.warn('Failed to refresh token. Logging out.', err);
                    window.location.replace("/");
                } else {
                    console.info('Failed to refresh token but did not receive error:', err);
                }
            });
        }, 2000)
    }, [auth])

    React.useEffect(() => {
        keycloak.init({ onLoad: 'check-sso' }).then(authenticated => {
            console.log("setting auth to:", authenticated)

            if (authenticated) {
                // load profile
                keycloak.loadUserInfo().then(p => {
                    setAuth({ keycloak: keycloak, authenticated: authenticated, initialized: true, profile: p })

                    console.log(keycloak.tokenParsed);

                    //to regenerate token on expiry
                    refreshToken();
                })
                    .catch(err => {
                        console.warn(err)
                        setAuth({ keycloak: keycloak, authenticated: authenticated, initialized: true, profile: null })
                    })
            } else {
                setAuth({ keycloak: keycloak, authenticated: authenticated, initialized: true, profile: null })
            }


        }).catch(err => console.error(err))
    }, [])

    if (!auth.initialized) {
        return loading;
    }

    // Add global interceptor for all axios request
    if (auth.authenticated) {
        setAuthForAxios(auth.keycloak.token);
    }

    return (
        <KeycloakContext.Provider value={auth}>
            {children}
        </KeycloakContext.Provider>
    )
}

export function useKeycloak() {
    const context = useContext(KeycloakContext);
    if (context === undefined) {
        throw new Error('useKeycloak must be used within a KeycloakProvider')
    }
    return context
}
