import React from "react";
import { Col, Spinner } from "react-bootstrap";
import axios from "axios";
import MessagesPerTimespanChart from "../graphs/MessagesPerTimespanChart"; // Import the chart component
import * as Constants from "../../utils/constants";

const MessagesPerTimespanPanel = ({ systemId = null }) => {
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const query = React.useCallback(() => {
        if (!systemId) return;

        setLoading(true);
        axios
            .get(`${Constants.API_BASE}api/diag/numberEvents?gateId=${systemId}`)
            .then((res) => { setData(res.data); })
            .catch((err) => {
                console.error(err);
                setData([]);
            })
            .finally(() => { setLoading(false); });
    }, [systemId]);

    React.useEffect(() => {
        query();
    }, [query]);

    if (!systemId) {
        console.warn("No gate ID set.");
        return null;
    }

    return (
        <Col xs={12} sm={6} className="panel">
            <h3 align="center"># Messages in the last 24h</h3>
            {loading ? (
                <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} />
            ) : data.length === 0 ? (
                <span className="mt-5 d-block text-center">No data available for the last 24 hours.</span>
            ) : (
                <MessagesPerTimespanChart data={data} height={285} />
            )}
        </Col>
    );
};

export default MessagesPerTimespanPanel;
