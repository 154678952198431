import axios from 'axios';
import React from "react";
import { useForm } from "react-hook-form";

import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'

import * as Constants from '../../utils/constants';
import { Helmet } from 'react-helmet';

const SystemCreate = function (props) {

    const { register, handleSubmit, formState: { errors} } = useForm();

    const [customerData, setCustomerData] = React.useState(null);
    const [locations, setLocations] = React.useState([])

    React.useEffect(() => {

        axios.get(Constants.API_BASE + 'api/location?CustomerId=1')
            .then(locationRes => {
                setLocations(locationRes.data);
            }).catch(err => console.error(err))


        axios.get(Constants.API_BASE + 'api/customer')
            .then(res => {
                setCustomerData(res.data);
            }).catch(err => console.error(err))


    }, [])

    const saveHandler = (formData) => {
        axios.post(Constants.API_BASE + 'api/gate', formData,)
            .then(res => {
                console.log(res);

                props.history.push({
                    pathname: '/systems',
                    state: { toast: `Created new system: ${formData.gateId}.` }
                });

            }).catch(err => console.error(err))
    }

    const updateLocations = async (evt) => {
        console.log("update")
        const value = evt.target.value
        try {
            axios.get(Constants.API_BASE + 'api/location?CustomerId=' + value)
                .then(locationRes => { setLocations(locationRes.data) })
        } catch (err) {
            console.warn(err)
        }
    }

    return (
        <Container className="mt-5">
            <Helmet>
                <title>Create System - Anubis</title>
            </Helmet>
            <Row>
                <Col>
                    <h2 className="text-center">Manually Create System</h2>
                    <Alert key="warning" variant="warning">
                        Unknown systems are created automatically by the log server or by the initialization tools. Use this form <strong>only</strong> for legacy offline systems or similar exceptions.
                    </Alert>
                    <Form onSubmit={handleSubmit(saveHandler)}>

                        <Form.Group className="mb-3">
                            <Form.Label>System ID</Form.Label>
                            <Form.Control type="text" {...register("gateId", { required: true })} />
                            <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                This name should be uniquely identify the system. Commonly it consists of the country code, location an number, e.g. DEUTT01.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Customer</Form.Label>
                            <Form.Control as="select" {...register("CustomerId", { required: true })} defaultValue={1} onChange={(evt) => updateLocations(evt)}>
                                {!!customerData && customerData.map((c) =>
                                    <option value={c.id} key={c.id}>{c.name}</option>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please select a customer</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Location</Form.Label>
                            <Form.Control as="select" {...register("LocationId", { required: true })} isInvalid={errors.LocationId}>
                                <option value="" key={0}>None</option>
                                {locations.map((c) =>
                                    <option value={c.id} key={c.id}>{c.name}</option>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please select a location</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check
                                type="switch"
                                id="switch-active"
                                label="System is active (considered in evaluation)"
                                {...register("active")}
                                defaultChecked={false}
                            />

                            <Form.Text className="text-muted">
                                Inactive systems are not shown or considered in various places, e.g. the last seen box on the home screen or the camera evaluation in the maintenance view.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control as="textarea" rows="3" {...register("comment")} placeholder="Enter an optional comment..." defaultValue={"Created manually."} />
                            <Form.Text className="text-muted">
                                Add additional information on this system, e.g. special setups, customer computers, IT integrations.
                            </Form.Text>
                        </Form.Group>

                        <Button variant="primary" className="float-end mt-1" type="submit">
                            Save
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default SystemCreate;
