import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from "chart.js";
import * as Constants from "../../utils/constants";

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const UsageProfileChart = ({ data }) => {
  const labels = data.map((item) => `${item.hourGrp}:00`);
  const chartData = {
    labels,
    datasets: [
      {
        label: "# Measurements",
        data: data.map((item) => item.nMeasurements),
        borderColor: Constants.PLOT_COLORS[0],
        tension: 0.2,
        pointRadius: 3,
        yAxisID: "yMeasurements",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "bottom" },
      tooltip: { enabled: true },
    },
    scales: {
      x: { title: { display: true, text: "Hour of the Day" } },
      yMeasurements: {
        type: "linear",
        position: "left",
        title: { display: true, text: "# of Measurements in the last 14 days" },
        grid: { drawOnChartArea: false },
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ height: 350 }}>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default UsageProfileChart;
