import React from "react";
import axios from "axios";
import * as Constants from "../../utils/constants";
import { Row, Col, Spinner } from "react-bootstrap";
import SensorDataChart from "../graphs/SensorDataChart"; // Import chart component

const SensorDataRow = ({ systemId, height = 250 }) => {
  const [sensorData, setSensorData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!systemId) return;

    setLoading(true);
    axios
      .get(`${Constants.API_BASE}api/diag/sensors?gateId=${systemId}`)
      .then((res) => setSensorData(res.data))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [systemId]);

  if (!systemId) {
    console.warn("No system ID set.");
    return null;
  }

  return (
    <Row>
      <Col xs={12} sm={6} className="panel">
        <h3 align="center">Temperature Sensors</h3>
        {loading ? (
          <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} />
        ) : sensorData.length === 0 ? (
          <span className="mt-5 d-block text-center">No sensor data available.</span>
        ) : (
          <SensorDataChart data={sensorData} height={height} type="temperature" />
        )}
      </Col>

      <Col xs={12} sm={6} className="panel">
        <h3 align="center">CPU Load and Clock</h3>
        {loading ? (
          <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} />
        ) : sensorData.length === 0 ? (
          <span className="mt-5 d-block text-center">No sensor data available.</span>
        ) : (
          <SensorDataChart data={sensorData} height={height} type="cpu" />
        )}
      </Col>
    </Row>
  );
};

export default React.memo(SensorDataRow);
