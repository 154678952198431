import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'

import MaintenanceEffortByWeek from "../graphs/MaintenanceEffortByWeek";
import MaintenanceEffortTable from "./MaintenanceEffortTable";
const MaintenanceRemoteEffort = (props) => {

  const plotData = props.data

  return (

    <Container fluid className="mb-5">
      <Row >
        <Col xs={12} sm={8} className="panel">
          <h3>Support Effort by Week
            <OverlayTrigger overlay={<Tooltip>Aggregated by week, limited to the past 24 months</Tooltip>} placement="bottom">
              <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginLeft: "8px" }} /></span>
            </OverlayTrigger>
          </h3>
          <MaintenanceEffortByWeek data={plotData?.effortByWeek} height={400} />
        </Col>
        <Col xs={12} sm={4} className="panel">
          <h3>High maintenance systems by effort
            <OverlayTrigger overlay={<Tooltip>Limited to the last 30 days.</Tooltip>} placement="bottom">
              <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginLeft: "8px" }} /></span>
            </OverlayTrigger>
          </h3>
          <div style={{ maxHeight: "350px", minHeight: "350px", overflowY: "scroll" }}>
            <MaintenanceEffortTable data={plotData.effortBySystem} rowKey="effortByWeek" dataKey="totalEffort" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenanceRemoteEffort;
