import React from "react";
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap'
import { useForm } from "react-hook-form";

import { API_BASE, Map_LogTypeNumeric_LogTypeString, Map_LogTypeString_LogTypeNumeric } from '../../utils/constants';

function EditMaintenanceLogModal({
    data = null,
    onChange = () => { },
    onHide = () => { },
    show = false,
}) {

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm()

    const [loading, setLoading] = React.useState(false);
    const [logType, setLogType] = React.useState(data?.type);

    React.useEffect(() => {
        console.log("effect")
        if (show === true) {
            setValue("description", data?.description)
            setValue("resolution", data?.resolution)
            setValue("effortMinutes", data?.effortMinutes)
            setLogType(Map_LogTypeNumeric_LogTypeString[data?.type])
        }
    }, [show, data?.description, data?.resolution, data?.effortMinutes, data?.type, setValue])


    const resetAndClose = () => {
        setLoading(false)
        setLogType(undefined)
        reset();
        onChange();
        onHide()
    }

    const onCancel = () => {
        resetAndClose();
    }

    const onSubmit = async formData => {

        if (logType === undefined) {
            alert("Log Type must be defined.")
            return;
        }

        setLoading(true)
        try {
            console.log(formData)
            const d = {
                description: formData.description,
                resolution: formData.resolution,
                logType: Map_LogTypeString_LogTypeNumeric[logType],
                effortMinutes: formData.effortMinutes
            }
            await axios.put(API_BASE + 'api/maintenancelog/' + data?.id, d)
            resetAndClose();
        } catch (err) {
            alert(err);
            setLoading(false);
        }
    }

    return (

        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <h3 className="text-center">Edit Entry in Maintenance Log</h3>

                    <Form.Group className="mb-2 text-center">
                        <Form.Label>Type:</Form.Label>
                        <Button variant={logType === "metrix" ? "primary" : "secondary"} size="sm" className="me-2 ms-2" onClick={() => setLogType("metrix")}>MetriX</Button>
                        <Button variant={logType === "system" ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType("system")}>System</Button>
                        <Button variant={logType === "camera" ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType("camera")}>Camera</Button>
                        <Button variant={logType === "scale" ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType("scale")}>Scale</Button>
                        <Button variant={logType === "barcode" ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType("barcode")}>Barcode</Button>
                        <Button variant={logType === "config" ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType("config")}>Configuration</Button>
                        <Button variant={logType === "general" ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType("general")}>General</Button>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Description *</Form.Label>
                        <Form.Control as="textarea" rows="3" placeholder="Description of the issue / the modifications" {...register("description", { required: true })} isInvalid={errors?.description} />
                        <Form.Control.Feedback type="invalid">The description is required.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Resolution</Form.Label>
                        <Form.Control as="textarea" rows="3" placeholder="Optional text that describes the solution to this issue" {...register("resolution")} isInvalid={errors?.resolution} />
                        <Form.Control.Feedback type="invalid">Please enter an e-mail</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Effort in Minutes*</Form.Label>
                        <Form.Control as="input" rows="3" placeholder="Effort in hours as float number." {...register("effortMinutes", { required: true })} isInvalid={errors?.effortMinutes} />
                        <Form.Control.Feedback type="invalid">Please estimate the effort in hours.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-2 text-center">
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 5)}>5&thinsp;min</Button>
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 10)}>10&thinsp;min</Button>
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 15)}>15&thinsp;min</Button>
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 20)}>20&thinsp;min</Button>
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 30)}>30&thinsp;min</Button>
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 45)}>45&thinsp;min</Button>
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 60)}>60&thinsp;min</Button>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="float-end" onClick={() => onCancel()} disabled={loading}>
                        Cancel
                    </Button>

                    <Button className="float-end me-2" type="submit" disabled={loading}>
                        {loading ? 'Loading...' : 'Save Changes'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default EditMaintenanceLogModal;