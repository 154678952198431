import React from "react";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'

import { useKeycloak } from "../utils/KeycloakHook";

const LogoutButton = () => {

  const { keycloak } = useKeycloak()

  return (
    <Button
      onClick={() => keycloak.logout()}
      variant="danger"
      className="btn-margin"
    >
      <FontAwesomeIcon icon={faArrowRightFromBracket} fontSize="20" />
    </Button>
  );
};

export default LogoutButton;
