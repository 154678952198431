import React from "react";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend, TimeScale } from "chart.js";
import { format } from "date-fns";
import * as Constants from "../../utils/constants";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend, TimeScale);

const CalibrationMetricChart = ({
  data = null,
  height = 250,
  leftName = "",
  rightName = " ROI",
  yLeftLabel = "Missing Pixels %",
  yRightLabel = "Missing Pixels ROI %",
  leftKey = " invalidRoi",
  rightKey = " invalid",
  scatterFailed = false,
}) => {
  if (!data || data.plotData.length === 0) {
    return <div style={{ height }}>No Data Available</div>;
  }

  // Extract serials and plot data
  const serials = data.serials;
  const plotData = data.plotData;

  // Define datasets
  const datasets = serials.flatMap((v, index) => [
    {
      label: `${v}${leftName}`,
      data: plotData.map((item) => ({ x: item.ts, y: item[v + leftKey] })),
      borderColor: Constants.CONTRAST_COLORS[index % Constants.PLOT_COLORS.length],
      borderWidth: 2,
      tension: 0.1,
      pointRadius: 0,
      yAxisID: "yLeft",
    },
    rightKey
      ? {
          label: `${v}${rightName}`,
          data: plotData.map((item) => ({ x: item.ts, y: item[v + rightKey] })),
          borderColor: Constants.CONTRAST_COLORS[index % Constants.PLOT_COLORS.length],
          borderWidth: 2,
          tension: 0.1,
          pointRadius: 0,
          borderDash: [5, 5],
          yAxisID: "yRight",
        }
      : null,
  ]).filter(Boolean);

  if (scatterFailed) {
    datasets.push({
      label: "Failed",
      data: plotData.map((item) => ({ x: item.ts, y: 1 })),
      borderColor: "#ff0000",
      borderWidth: 2,
      pointRadius: 2,
      pointBackgroundColor: "red",
      showLine: false,
      yAxisID: "yScatter",
    });
  }

  const chartData = { datasets };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top" },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        type: "time",
        time: { unit: "day", tooltipFormat: "dd.MM.yy" },
        title: { display: true, text: "Date" },
      },
      yLeft: {
        position: "left",
        title: { display: true, text: yLeftLabel },
      },
      yScatter: {
        position: "left",
        title: { display: false },
      },
      ...(rightKey // spread into object if rightkey is set
        ? {
            yRight: {
              position: "right",
              title: { display: true, text: yRightLabel },
              beginAtZero: false,
              grid: { drawOnChartArea: false },
            },
          }
        : {}),
    },
  };

  return <div style={{ height }}><Line data={chartData} options={chartOptions} /></div>;
};

export default CalibrationMetricChart;
