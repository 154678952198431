
import React from "react";
import axios from 'axios';
import { useForm } from "react-hook-form";

import { Alert, Spinner, Table, Container, Row, Col, Form, Button, Modal } from "react-bootstrap"

import XpuIdSuggestion from '../../components/XpuIdSuggestion'
import * as Constants from '../../utils/constants';
import { SubNavigation, NotificationModal } from "../../components";

import { productionNavStates, productionNavTitle } from "../../utils/navStatesProduction";
import { generatePassword } from "../../utils/password";
import useModal from "../../utils/useModal";

function getLicenseExpirationDate() {
  const date = new Date()
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();
  const nextYear = currentMonth >= 0 && currentMonth <= 10 ? currentYear + 1 : currentYear + 2;

  return `${nextYear}-03-31`;
}

const ToggleHelp = (props) => {

  const [show, setShow] = React.useState(false);

  if (!show) {
    return (
      <div className="text-center">
        <Button onClick={() => setShow(!show)} variant="secondary">Show Help</Button>
      </div>)

  } else {
    return (<>
      <p>The information collected in this form will be used to automatically perform several initialization steps:</p>
      <ul>
        <li>Create a record for the new system in the log server's database</li>
        <li>Create credentials for the new system that can be used to authenticate at <code>auth.metrilus.de</code></li>
        <li>Create configuration files and licenses</li>
      </ul>
      <p>The resulting files will be stored on the company NAS and can be found via the provided system id.</p>
      <div className="text-center">
        <Button onClick={() => setShow(!show)} >Hide Help</Button>
      </div>
    </>)
  }

}

const InitSystem = (props) => {

  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({ defaultValues: { generation: "2" }});

  const [customerData, setCustomerData] = React.useState(null);
  const [locations, setLocations] = React.useState([])
  const [setupData, setSetupData] = React.useState({ configDirectories: [], calibrations: [] })
  const [processModal, setProcessModal] = React.useState(null)
  const {data: notificationData, setData: setNotificationData} = useModal();

  const showLftNote = watch('hostname')?.includes("LFT") && (watch('isLegalForTrade') !== true)

  React.useEffect(() => {
    axios.get(Constants.API_BASE + 'api/customer')
      .then(res => setCustomerData(res.data))
      .catch(err => console.error(err))

    axios.get(Constants.API_BASE + 'api/setup/listAvailableFiles')
      .then(res => setSetupData(res.data))
      .catch(err => console.error(err))
  }, [])

  const updateLocations = async (evt) => {
    const value = evt.target.value
    try {
      axios.get(Constants.API_BASE + 'api/location?CustomerId=' + value)
        .then(locationRes => { setLocations(locationRes.data) })
    } catch (err) {
      console.warn(err)
    }
  }

  const onSubmit = formData => {

    setProcessModal({ status: "pending" })
    formData.generation = parseInt(formData.generation);

    axios.post(Constants.API_BASE + 'api/setup/createSetupBundle', formData)
      .then(res => {
        console.log("result", res.data);
        setProcessModal(res.data)
      }).catch(err => {
        setNotificationData({title: "Failed", text: err.message})
        setProcessModal(null)
      })
  }

  if (customerData === null) {
    return "loading...";
  }

  const defaultExiprationDate = getLicenseExpirationDate()
  const generatedPassword = generatePassword(10)

  return (
    <>
      <SubNavigation pages={productionNavStates} title={productionNavTitle} />
      <NotificationModal data={notificationData} />
      <Container className="container-sm" size="sm">
        <Modal show={processModal !== null} backdrop="static" onHide={() => setProcessModal(null)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>System Initialization</Modal.Title>
          </Modal.Header>
          {processModal === null || processModal.status === "pending" ? (
            <Modal.Body><Spinner animation="border" style={{ position: "relative", top: "25%", left: "50%" }} /></Modal.Body>
          ) : (
            <Modal.Body>
              <p>The initialization process has finished. Information on the initial steps is listed below:</p>
              <Table size="sm" responsive>
                <tbody>
                  {processModal.log.map((v, index) => {
                    let color = "#ff0000";
                    let text = "unk";

                    switch (v.level) {
                      case "info":
                        color = Constants.COLORS.INFO
                        text = "info"
                        break;
                      case "error":
                        color = Constants.COLORS.ERROR
                        text = "error"
                        break;
                      case "warning":
                        color = Constants.COLORS.WARNING
                        text = "warning"
                        break;
                      default:
                        color = "#ff0000"
                        text = "unk"
                        break;
                    }

                    return (<tr key={"log-" + index}>
                      <td style={{ "width": 20 }}>{index + 1}</td>
                      <td style={{ "width": 50, textAlign: "center", "color": color }}>{text}</td>
                      <td>{v.message}</td>
                    </tr>)
                  })}
                </tbody>
              </Table>
              {processModal.success === true ? (
                <Alert variant="success">Initialization successful. Proceed with initialization on system.</Alert>
              ) : (
                <Alert variant="danger">Initialization failed. Error analysis and potential manual data cleanup may be required.</Alert>
              )}
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setProcessModal(null)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => setProcessModal(null)}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>

        <h2 className="mt-3 text-center">Initialize a new System</h2>
        <Row className="justify-content-center">
          <Col className="col-6">
            <Form onSubmit={handleSubmit(onSubmit)}>

              <ToggleHelp />

              <h4 className="text-center mt-3">Deployment Information</h4>
              <p className="text-center">Customer and/or the location must be created prior to using this form if one or the other does not exist yet.</p>
              <Form.Group className="mb-1">
                <Form.Label>Customer</Form.Label>
                <Form.Control as="select" {...register("customerId" , { required: true })} isInvalid={errors?.customerId} onChange={(evt) => updateLocations(evt)}>
                  {customerData.map((c) =>
                    <option value={c.id} key={c.id}>{c.name}</option>
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">Please select a customer</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>Location</Form.Label>
                <Form.Control as="select" {...register("locationId", { required: true })} isInvalid={errors?.locationId} >
                  {locations.map((c) =>
                    <option value={c.id} key={c.id}>{c.name}</option>
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">Please select a location</Form.Control.Feedback>
              </Form.Group>

              <h4 className="text-center mt-5">System Information</h4>
              <XpuIdSuggestion onClick={x => setValue('hostname', x)} />
              <Form.Group className="mb-1">
                <Form.Label>Hostname</Form.Label>
                <Form.Control type="text" {...register("hostname", { required: true })} isInvalid={errors?.hostname} />
                <Form.Control.Feedback type="invalid" placeholder="XPU-...">Please enter a name</Form.Control.Feedback>
                <Form.Text className="text-muted">
                  The host name is a Metrilus-wide unique identifier for the XPU. Unlike the system id, it is never assigned to another system in case of a hardware replacement.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>System Id</Form.Label>
                <Form.Control type="text"  {...register("systemId", { required: true })} isInvalid={errors?.systemId} />
                <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                <Form.Text className="text-muted">
                  The system id consists of the country code, three letters that represent the city name and two digit number, e.g. DEUTT01.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>Service Password</Form.Label>
                <Form.Control type="text" {...register("servicePassword", { required: false })} isInvalid={errors?.servicePassword} placeholder="Enter a service password if this is a LFT system" defaultValue={generatedPassword}/>
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>

              

              <Form.Group className="mb-1">
                <Form.Label>Is Legal for Trade</Form.Label>
                { showLftNote && (
                <Alert variant="warning" className="p-1">The hostname contains 'LFT'. Is this a LFT system?</Alert>
              )}
                <Form.Check
                  {...register("isLegalForTrade", { required: false })}
                  type="switch"
                  id="isLegalForTrade"
                  label="Enable if this is a LFT system"
                  size={"lg"}
                />
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>License Expiration</Form.Label>
                <Form.Control type="date" {...register("licenseExpiration", { required: true })} isInvalid={errors?.servicePassword} defaultValue={defaultExiprationDate} />
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Applies only to MetriX 2 systems. Field is ignored for MetriX 1.
                </Form.Text>
              </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label>Generation</Form.Label>
              <Form.Control as="select" {...register("generation", { required: true })} isInvalid={errors?.generation}>
                <option value="1">MetriX 1.x.x</option>
                <option value="2">MetriX 2.x.x</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">Please select a level</Form.Control.Feedback>
            </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>System Type</Form.Label>
                <Form.Control as="select" {...register("systemType", { required: true })} isInvalid={errors?.systemType}>
                  {setupData.configDirectories.map((c) =>
                    <option value={c.path} key={c.path}>{c.type} {c.name}</option>
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">Please select a level</Form.Control.Feedback>
                <Form.Text className="text-muted">
                  This dropdown shows all system types which have configuration folders in  <code style={{ fontSize: "100%" }}>config_metrix1</code> or <code style={{ fontSize: "100%" }}>config_metrix2</code>.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>Smarteye RBT (if required)</Form.Label>
                <Form.Control as="select" {...register("smarteyeRbt", { required: false })} isInvalid={errors?.smarteyeRbt}>
                  <option value="">not required</option>
                  {setupData.calibrations.map((c) =>
                    <option value={c.name} key={c.createdAt}>{c.name}</option>
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">Please select a level</Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Smart eyes require additional calibration files. All cameras, that are shown here, have existing calibration files in the shared calibration store. The dropdown's values are sorted by their date in descending order.
                </Form.Text>
              </Form.Group>

              <Button variant="primary" className="float-end mt-3 mb-5" type="submit">
                Create Configuration Bundle
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default InitSystem;
