import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from "chart.js";
import { format } from "date-fns";
import CenteredSpinner from "../CenteredSpinner";
import * as Constants from '../../utils/constants';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const SystemUsageChart = function ({data, secondData}) {

  if (!data) {
    return <CenteredSpinner />
  }

  const formatChartData = (data, locationData) => {
    const labels = data.map(item => format(new Date(item.block * 1000), "HH:mm"));

    const datasets = [
      {
        label: "Hourly Measurements",
        data: data.map(item => parseInt(item.cnt)),
        borderColor: Constants.PLOT_COLORS[0],
        tension: 0.2,
        pointStyle: true,
        borderWidth: 2,
        fill: false,
        yAxisID: "yTotal",
        type: "line",
      }
    ];

    if (locationData) {
      if (locationData.length !== data.length) {
        console.warn("Primary and second data arrays differ in length.");
        return { labels, datasets };
      }

      const secondKeys = Object.keys(locationData[0]).filter(key => key !== "block");

      secondKeys.forEach((key, index) => {
        datasets.push({
          label: key,
          data: locationData.map(item => parseInt(item[key])),
          backgroundColor: Constants.CONTRAST_COLORS[index % Constants.CONTRAST_COLORS.length],
          yAxisID: "yLocation",
        });
      });
    }

    return { labels, datasets };
  };

  const chartData = formatChartData(data, secondData);

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'bottom' },
      title: { display: false },
    },
    scales: {
      x: { title: { display: true, text: "Time" } },
      yTotal: {
        position: "left",
        title: { display: true, text: "Total Measurements" },
      },
    },
  };

  if (secondData) {
    chartOptions.scales.yLocation = {
      position: "right",
      title: { display: true, text: "Measurements at Location" },
      grid: { drawOnChartArea: false },
    };
  }

  return <Bar data={chartData} options={chartOptions} />;
};

export default SystemUsageChart;
