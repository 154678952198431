import axios from "axios";
import React from "react";
import { Col, Spinner } from "react-bootstrap";
import { API_BASE } from "../utils/constants";
import MessagesPerTimespanChart from "./graphs/MessagesPerTimespanChart";

const NumberMessages24hPanel = () => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    axios
      .get(`${API_BASE}api/panels/numberMessages24h`)
      .then((res) => setData(res.data))
      .catch((err) => {
        console.warn(err);
        setData([]);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Col xs={12} md={12} className="panel">
      <h3># Unpleasant Messages in the Last 24h</h3>

      {loading ? (
        <Spinner animation="border" style={{ position: "relative", top: "25%", left: "50%" }} />
      ) : (
        <MessagesPerTimespanChart data={data} height="300px" />
      )}
    </Col>
  );
};

export default NumberMessages24hPanel;
