import React from "react";
import { Bubble } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, Tooltip, Legend, TimeScale } from "chart.js";
import "chartjs-adapter-date-fns";
import { addHours, format } from "date-fns";

ChartJS.register(CategoryScale, LinearScale, PointElement, Tooltip, Legend, TimeScale);

const EVENT_TYPE_MAP = {
  2: { label: "Startup", yValue: 2, color: "#FF5733" },
  3: { label: "Init", yValue: 3, color: "#FFBD33" },
  4: { label: "Calib Success", yValue: 4, color: "#33FF57" },
  5: { label: "Calib Failed", yValue: 5, color: "#33A1FF" },
  6: { label: "Lost Device", yValue: 6, color: "#8D33FF" },
  7: { label: "Lost Local", yValue: 7, color: "#FF33A6" },
  8: { label: "Lost Remote", yValue: 8, color: "#A633FF" },
};

const formatChartData = (data) => {
  if (!data || data.length === 0) return { datasets: [] };

  const datasets = Object.keys(EVENT_TYPE_MAP).map((eventType) => {
    const filteredData = data.filter((item) => item.eventType === parseInt(eventType));

    return {
      label: EVENT_TYPE_MAP[eventType].label,
      data: filteredData.map((item) => ({
        x: item.block,
        y: EVENT_TYPE_MAP[eventType].yValue,
        r: Math.min(Math.sqrt(item.cnt) * 5, 22),
        c: item.cnt
      })),
      backgroundColor: EVENT_TYPE_MAP[eventType].color,
    };
  });

  return { datasets };
};

const EventsPerTimespanChart = ({ data, height }) => {
  if (!data || data.length === 0) {
    return <p style={{ textAlign: "center", marginTop: 20 }}>No non-measurement events occurred in the last 7 days</p>;
  }

  console.log(data)
  const chartData = formatChartData(data);

  const minDate = addHours(new Date(data[0].block), -12);
  const maxDate = addHours(new Date(data[data.length - 1].block), 12);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false, position: "bottom" }, tooltip: {
        callbacks: {
          label: (context) => {
            const time = format(context.raw.x, "dd.MM.yyyy HH:mm"); // Format time
            return [time, `Count: ${context.raw.c}`]; // Show time and count
          },
        }
      }
    },
    scales: {
      x: {
        type: "time",
        time: { unit: "day", tooltipFormat: "d.M HH:mm" },
        min: minDate,
        max: maxDate,
        title: { display: true, text: "Time" },
      },
      y: {
        min: 1.5,
        max: 8.5,
        ticks: { callback: (value) => Object.values(EVENT_TYPE_MAP).find((e) => e.yValue === value)?.label || "" },
        title: { display: true, text: "Event Type" },
      },
    },
  };

  return <div style={{ height: height }}><Bubble data={chartData} options={chartOptions} /></div>;
};

export default EventsPerTimespanChart;
