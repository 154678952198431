import React from 'react';
import { format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const FormattedDate = ({ local, format: dateFormat, children }) => {

  if (children === null || children === undefined)
    return <i>null</i>

  let date = typeof children === 'string' ? parseISO(children) : children;

  // Convert UTC to local time if the `local` prop is true
  if (local) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    date = toZonedTime(date, timeZone);
  }

  const formattedDate = format(date, dateFormat);

  return <>{formattedDate}</>;
};

export default FormattedDate;