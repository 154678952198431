import React from "react";
import { Col, Spinner } from "react-bootstrap";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import TooltipComponent from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import UsageProfileChart from "../graphs/UsageProfileChart"; // Import the chart component
import * as Constants from "../../utils/constants";

const UsageProfilePanel = ({ systemId = null }) => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (systemId === null) return;

    setLoading(true);
    axios
      .get(`${Constants.API_BASE}api/panels/usageProfile?systemId=${systemId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [systemId]);

  if (systemId === null) {
    console.warn("No system ID set.");
    return null;
  }

  return (
    <Col xs={12} sm={3} className="panel">
      <h3 align="center">
        Usage Profile
        <OverlayTrigger overlay={<TooltipComponent>Average number of type-1 events in the last 14 days.</TooltipComponent>} placement="bottom">
          <span>
            <FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginBottom: "0px", marginLeft: "8px" }} />
          </span>
        </OverlayTrigger>
      </h3>

      {loading ? (
        <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} />
      ) : data.length === 0 ? (
        <span className="mt-5 d-block text-center">No data for the last 14 days.</span>
      ) : (
        <UsageProfileChart data={data} />
      )}
    </Col>
  );
};

export default React.memo(UsageProfilePanel);
