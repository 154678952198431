import React from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import stripJsonComments from 'strip-json-comments';

const ConfigEditorComponent = function ({
    defaultData = {
        id: -1,
        type: null,
        json: null
    },
    showDelete = false,
    saveHandler = x => console.log(x),
    deleteHandler = alert,
    help = <p class="text-danger">No help specified</p>,
}) {

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleDeleteModalConfirmed = (formData) => {
        setShowDeleteModal(false);
        deleteHandler(formData);
    }

    const handleDeleteModalShow = () => setShowDeleteModal(true);
    const onSubmit = data => saveHandler(data);

    const jsonValidator = (value) => {
        try {
            JSON.parse(stripJsonComments(value));
            return true;
        } catch {
            return "Cannot parse JSON. Please check for syntax errors.";
        }
    }

    return (
        <Row>
            <Col>
                <h4 style={{ marginLeft: 0 }}>{(defaultData.type) ? defaultData.type : "unknown"}</h4>
                {help}
                <Form onSubmit={handleSubmit(onSubmit)} className="mb-3 clearfix">
                    <Form.Group>
                        <Form.Label>JSON configuration:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="10"
                            placeholder="This should be the config"
                            {...register("json", { required: true, validate: jsonValidator })}
                            isInvalid={errors?.json}
                            defaultValue={(defaultData.json != null) && defaultData.json}
                        className="code"
                        />
                        <Form.Control.Feedback type="invalid">{errors?.json?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <input type="hidden" {...register("id", {})} value={defaultData.id} />
                    <input type="hidden" {...register("type", {})} value={defaultData.type} />

                    <Button variant="success" className="float-end mt-1" type="submit">
                        Save {defaultData.type}
                    </Button>
                    {showDelete && (
                        <Button variant="danger" className="float-end me-1 mt-1" onClick={handleDeleteModalShow}>
                            Delete {defaultData.type}
                        </Button>
                    )}
                </Form>

                {showDelete && (
                    <Modal show={showDeleteModal} onHide={handleDeleteModalConfirmed}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Config: {(defaultData.type) ? defaultData.type : "unknown"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Please confirm that this configuration should be deleted.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => setShowDeleteModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={() => handleDeleteModalConfirmed(defaultData)}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Col>
        </Row>
    );
};

export default ConfigEditorComponent;
