import React from "react";
import axios from 'axios';
import { Form, Button } from "react-bootstrap"
import { Link } from "react-router-dom";

import { LoadingSpinner } from ".";

import * as Constants from '../utils/constants';


const HomeTimetrackingPanel = function (props) {

    const [data, setData] = React.useState([]);
    const [selected, setSelected] = React.useState(null);
    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/customer')
            .then(res => {
                setData(res.data);
            })
    }, [])

    if (data === null) return <LoadingSpinner text="Loading customers..." />

    const onSelect = (e) => { setSelected(e.target.value) }

    return (
        <>
            <Form.Select size="sm" onChange={(e) => onSelect(e)}>
                {data.map((v, i) => <option key={'trp' + i} value={v.id}>{v.name}</option>)}
            </Form.Select>

            <Button as={Link} to={"/timetrack/create?customerId=" + selected} className="float-end mt-2" disabled={selected === null}>
                Create Record
            </Button>
        </>
    );
};

export default HomeTimetrackingPanel;