import axios from 'axios';
import React from "react";
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';

import {BinaryDescisionModal, NotificationModal, UpdateDownloadModal} from '../../components/';

import useModal from "../../utils/useModal";
import * as Constants from '../../utils/constants';

const DownloadUpdate = function (props) {

    const cId = props?.match?.params?.app;
    const [data, setData] = React.useState(null);
    const [deleteModal, setDeleteModal] = React.useState(null);
    const [upload, setUpload] = React.useState(null);
    const { data: notificationData, setData: setNotificationData } = useModal();

    const { register, handleSubmit } = useForm();

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/download/' + cId)
            .then(res => {
                setData(res.data);
            }).catch(err => console.error(err))
    }, [cId])


    if (!cId) {
        console.error("Download ID is not set.")
        return "Download id is not set";
    }

    if (data === null) {
        return "loading...";
    }

    const showModal = (formData) => {
        setDeleteModal(formData)
    }

    const saveHandler = (formData) => {
        axios.put(Constants.API_BASE + 'api/download/' + cId, formData)
            .then(res => {
                props.history.push({
                    pathname: '/downloads',
                    state: { toast: `${formData.app} saved.` }
                });
            }).catch(err => setNotificationData({ title: "Update Failed", text: err.message }))
    }

    return (
        <Container className="mt-3">
            <Helmet>
                <title>Software Update - Anubis</title>
            </Helmet>
            <NotificationModal data={notificationData} />

            <BinaryDescisionModal yes={() => saveHandler(deleteModal)} no={() => setDeleteModal(null)} title={"Are you sure?"} text={"Have you double checked all settings?"} show={deleteModal !== null} />

            <UpdateDownloadModal data={upload} show={upload !== null} onHide={() => setUpload(null)} onChange={() => { setUpload(null) }} />

            <Row>
                <Col>
                    <h2 className="text-center">Edit Download Information</h2>
                    <Alert key="danger" variant="danger">
                        Setting wrong values here is a good way to start mayhem at customer sites. Roll out new version only if all software tests have been successful. You have been warned!
                    </Alert>

                </Col>
            </Row>

            <Row>
                <Col style={{ justifyContent: 'right', display: 'flex' }}>
                    <Button variant="primary" className="mt-1" onClick={() => setUpload(data)}>
                        Upload new Version
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col>

                    <Form onSubmit={handleSubmit(showModal)} >

                        <Form.Group className="mb-3">
                            <Form.Label>App</Form.Label>
                            <Form.Control type="text" {...register("app", { required: true })} defaultValue={data?.app && data?.app} readOnly={true} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Update Type</Form.Label>
                            <Form.Control as="select" {...register("type", { required: true })} defaultValue={data?.type && data?.type}>
                                <option value="exe">exe (Executable/bundled installer)</option>
                                <option value="msi">msi (MSI installer)</option>
                                <option value="plugin">plugin (Single file/DLL file, copied to MetriX directory)</option>
                                <option value="taskmsi">taskmsi (MSI installer which is scheduled. Use for UpdateTask self update only)</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please select a customer</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Checksum</Form.Label>
                            <Form.Control type="text" {...register("checksum", { required: true })} defaultValue={data?.checksum && data?.checksum} />
                            <Form.Control.Feedback type="invalid">Checksum is required</Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                The checksum is used to check for intentionally and unintentionally corrupted files. It is set automatically on newly uploaded files.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Filename</Form.Label>
                            <Form.Control type="text" {...register("filename", { required: true })} defaultValue={data?.filename && data?.filename} />
                            <Form.Control.Feedback type="invalid">Please enter a filename</Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                The filename is stored to properly name the downloaded files on the client systems.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Google Drive File Id</Form.Label>
                            <Form.Control type="text" {...register("fileId", {})} defaultValue={data?.fileId && data?.fileId} />
                            <Form.Text className="text-muted">
                                Used by the UpdateTask to download from Google Drive. The Update Task automatically falls back to the local log server if no file id is provided, or if the connection to Google Drive is blocked.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Kill/Execute prior/after the update</Form.Label>
                            <Form.Control type="text" {...register("executeAfterUpdate", {})} defaultValue={data?.executeAfterUpdate && data?.executeAfterUpdate} />
                            <Form.Text className="text-muted">
                                Use this field to provide an executable which needs to be stopped and restarted during the update process.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check
                                type="switch"
                                id="switch-active"
                                label="Process needs to be killed"
                                {...register("kill")}
                                defaultChecked={data?.kill}
                            />

                            <Form.Text className="text-muted">
                                Check to enable process stopping and starting.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check
                                type="switch"
                                id="switch-active"
                                label="Silent Update"
                                {...register("updateSilently")}
                                defaultChecked={data?.updateSilently}
                            />

                            <Form.Text className="text-muted">
                                Silent updates can be executed without interfering with operations. MetriX updates should not be silent. Such updates are only executed if the system has not been used for a predefined amount of times.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>.NET Version</Form.Label>
                            <Form.Control type="number" {...register("netVersion", { required: false })} defaultValue={data?.netVersion && data?.netVersion} />
                            <Form.Text className="text-muted">
                                Define the major .NET version required for this application. This will enable safety checks prior to installing new versions. Example: 8 for all applications which use any version of the NET SDK 8.x.x.
                            </Form.Text>
                        </Form.Group>

                        <Button variant="primary" className="float-end mt-1" type="submit">
                            Save
                        </Button>
                    </Form>
                </Col>
            </Row>


        </Container>
    );
};

export default DownloadUpdate;
