import React from "react";
import axios from 'axios';
import { Modal, Button, Form, Alert } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import * as Constants from '../utils/constants';

function UpdateDownloadModal(props) {

    const history = useHistory() ;

    const { register, handleSubmit, formState: { errors }, reset } = useForm({ defaultValues: { name: props.data?.name } })
    const [loading, setLoading] = React.useState(false);

    const resetAndClose = () => {
        setLoading(false)
        reset();
        props.onChange();
        props.onHide()
    }

    const onCancel = () => {
        resetAndClose();
    }

    const onSubmit = async formData => {

        const fd = new FormData();
        fd.append("file", formData.file[0])
        fd.append("version", `${formData.major}.${formData.minor}.${formData.patch}`)

        setLoading(true)
        const url = Constants.API_BASE + 'api/download/' + props.data?.app;
        console.log(formData)
        try {
            await axios.post(url, fd, { headers: { "Content-Type": "multipart/form-data", }, timeout: 0 })
            resetAndClose();
            history.push({
                pathname: '/downloads',
                state: { toast: `Uploaded new version for ${props.data?.app}.` }
            });
        } catch (err) {
            alert(err);
            setLoading(false);
        }
    }

    const versions = props.data?.version?.split('.');
    let major = 0;
    let minor = 0;
    let patch = 0;
    if (versions !== undefined && versions.length === 3) {
        major = versions[0];
        minor = versions[1];
        patch = versions[2];
    }

    return (

        <Modal
            show={props.show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
        >
            <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <Modal.Body>
                    <h3 className="text-center mt-4">Upload new Version</h3>

                    <Alert variant="danger">New version will be rolled out immediately to all systems that are configured to update this app.</Alert>

                    <Form.Group className="mb-3">
                        <Form.Label>App</Form.Label>
                        <Form.Control type="text" defaultValue={props.data?.app && props.data?.app} disabled={true} />
                        <input type="hidden" value={props.data?.app && props.data?.app} {...register("app", { required: true })} />
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Version Major</Form.Label>
                        <Form.Control type="text" placeholder="1" {...register("major", { required: true })} isInvalid={errors?.major} defaultValue={major} />
                        <Form.Control.Feedback type="invalid">Major missing</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Version Minor</Form.Label>
                        <Form.Control type="text" placeholder="0" {...register("minor", { required: true })} isInvalid={errors?.minor} defaultValue={minor} />
                        <Form.Control.Feedback type="invalid">Minor missing</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Version Patch</Form.Label>
                        <Form.Control type="text"  placeholder="0" {...register("patch", { required: true })} isInvalid={errors?.patch} defaultValue={patch} />
                        <Form.Control.Feedback type="invalid">Patch missing</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>File</Form.Label>
                        <Form.Control type="file" placeholder="0" {...register("file", { required: true })} isInvalid={errors?.file} style={{ border: 'None' }} />
                        <Form.Control.Feedback type="invalid">File missing</Form.Control.Feedback>
                    </Form.Group>


                </Modal.Body>
                <Modal.Footer>
                    {loading && (
                        <Alert variant="primary">This request may take some time. The logserver internally uploads the update to Google Drive. Please wait...</Alert>
                    )}

                    <Button className="float-end" onClick={() => onCancel()} disabled={loading}>
                        Cancel
                    </Button>

                    <Button className="float-end me-2" type="submit" disabled={loading}>
                        {loading ? 'Loading...' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

UpdateDownloadModal.defaultProps = {
    onChange: () => { }
}

export default UpdateDownloadModal;