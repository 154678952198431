import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from "chart.js";
import { format } from "date-fns";
import * as Constants from "../../utils/constants";

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const formatAsHourMinute = (timestamp) => format(new Date(timestamp * 1000), "HH:mm");

const SensorDataChart = ({ data, height, type }) => {
  const labels = data.map((item) => formatAsHourMinute(item.ts));

  const datasets =
    type === "temperature"
      ? [
          { label: "CPU Temp.", data: data.map((item) => item.cpuTemp), borderColor: Constants.CONTRAST_COLORS[0], borderWidth: 2, tension: 0.2, pointRadius: 0 },
          { label: "HDD Temp.", data: data.map((item) => item.hddTemp), borderColor: Constants.CONTRAST_COLORS[1], borderWidth: 2, tension: 0.2, pointRadius: 0 },
          { label: "MB1 Temp.", data: data.map((item) => item.mb1Temp), borderColor: Constants.CONTRAST_COLORS[2], borderWidth: 2, tension: 0.2, pointRadius: 0 },
          { label: "MB2 Temp.", data: data.map((item) => item.mb2Temp), borderColor: Constants.CONTRAST_COLORS[3], borderWidth: 2, tension: 0.2, pointRadius: 0 },
        ]
      : [
          { label: "CPU Load", data: data.map((item) => item.cpuLoad), borderColor: Constants.CONTRAST_COLORS[0], borderWidth: 2, tension: 0.2, pointRadius: 0, yAxisID: "yLoad" },
          { label: "CPU Clock", data: data.map((item) => item.cpuClock), borderColor: Constants.CONTRAST_COLORS[1], borderWidth: 2, tension: 0.2, pointRadius: 0, yAxisID: "yClock" },
        ];

  const chartData = { labels, datasets };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { position: "bottom" }, tooltip: { enabled: true } },
    scales: {
      x: { title: { display: true, text: "Time" } },
      ...(type === "temperature"
        ? {
            y: { title: { display: true, text: "Temperature °C" }, beginAtZero: false },
          }
        : {
            yLoad: { position: "left", title: { display: true, text: "Load %" }, beginAtZero: true },
            yClock: { position: "right", title: { display: true, text: "Clock Speed MHz" }, beginAtZero: false, grid: { drawOnChartArea: false } },
          }),
    },
  };

  return <div style={{ height }}><Line data={chartData} options={chartOptions} /></div>;
};

export default SensorDataChart;
