import axios from 'axios';
import React from "react";
import { Container, Row, Col, Button, Form, Table } from 'react-bootstrap'
import { useForm } from 'react-hook-form';

import {AddPersonModal, BinaryDescisionModal, EditPersonModal, NotificationModal} from "../../components";

import * as Constants from '../../utils/constants';
import useModal from "../../utils/useModal";

const LocationUpdate = function (props) {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const locationId = props?.match?.params?.id;
    const [data, setData] = React.useState(null);
    const [addPersonShow, setAddPersonShow] = React.useState(false);
    const [editPersonShow, setEditPersonShow] = React.useState(null);
    const [showDescisionModal, setShowDescisionModal] = React.useState(null);
    const { data: notificationData, setData: setNotificationData } = useModal();

    const queryLocation = async () => {
        try {
            axios.get(Constants.API_BASE + 'api/location/' + locationId + '?withContacts=1')
                .then(res => { return setData(res.data) })
        } catch (err) {
            console.warn(err)
        }
    }

    React.useEffect(() => {
        try {
            axios.get(Constants.API_BASE + 'api/location/' + locationId + '?withContacts=1')
                .then(res => { return setData(res.data) })
        }
        catch (err) {
            console.error(err)
        }

    }, [locationId])

    if (!locationId) {
        console.error("Location ID is not set.")
        return "Location id is not set";
    }

    if (data === null) {
        return "loading...";
    }

    const onSubmit = async (formData) => {
        try {
            console.log("hdakjdask")
            await axios.put(Constants.API_BASE + 'api/location/' + locationId, formData)
            props.history.push({ pathname: '/locations', state: { toast: `${formData.name} saved.` } });
        }
        catch (err) {
            setNotificationData({ title: "Update Failed", text: err.message })
        }
    }

    const removeContactHandler = async () => {
        console.log("remove this please:", showDescisionModal)
        try {
            await axios.delete(Constants.API_BASE + 'api/location/' + locationId + '/contact/' + showDescisionModal.id)
            await queryLocation()
        }
        catch (err) {
            setNotificationData({ title: "Failed", text: err.message });
        }
        setShowDescisionModal(null)
    }

    return (
        <Container className="mt-5">
            <NotificationModal data={notificationData} />
            <AddPersonModal show={addPersonShow !== false} target="location" id={locationId} onHide={() => setAddPersonShow(false)} onChange={() => { queryLocation(); setAddPersonShow(false) }} />
            <BinaryDescisionModal
                show={showDescisionModal !== null}
                title="Remove Contact"
                text={"Would you like to remove " + showDescisionModal?.name + " from the list of associated persons for this system?"}
                yes={() => removeContactHandler()}
                no={() => setShowDescisionModal(null)}
            />
            <EditPersonModal data={editPersonShow} show={editPersonShow !== null} onHide={() => setEditPersonShow(null)} onChange={() => { queryLocation(); setEditPersonShow(null) }} />

            <Row>
                <Col>
                    <h3 className="text-center mt-3">{data.name}</h3>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label>Location Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter location name..." {...register("name", { required: true })} isInvalid={errors?.name} defaultValue={data.name && data.name} />
                            <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>UN/LOCODE from <a href="https://unece.org/trade/cefact/unlocode-code-list-country-and-territory">UNECE website</a></Form.Label>
                            <Form.Control type="text" placeholder="Enter UN/LOCODE..." {...register("locode", { required: true })} isInvalid={errors?.locode} defaultValue={data.locode && data.locode} />
                            <Form.Control.Feedback type="invalid">Please enter the UN/LOCODE for this location.</Form.Control.Feedback>
                        </Form.Group>

                        <Button variant="primary" className="float-end mt-1" type="submit">
                            Save
                        </Button>
                    </Form>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <h3 align="center" className="mt-2">Related Persons</h3>
                    <Table size="sm" responsive>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>E-Mail</th>
                                <th>Phone</th>
                                <th>Description</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data != null && data.Contacts.map((v, index) => {
                                return (
                                    <tr key={"contact" + index}>
                                        <td>{v.name}</td>
                                        <td>{v.email}</td>
                                        <td>{v.phone}</td>
                                        <td>{v.description}</td>
                                        <td>
                                            <Button className="float-end" size="sm" type="submit" onClick={() => setShowDescisionModal(v)}>Remove</Button>
                                            <Button className="float-end me-1" size="sm" type="submit" onClick={() => setEditPersonShow(v)}>Edit</Button>
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </Table>
                    <Button className="float-end" size="sm" type="submit" onClick={() => setAddPersonShow(true)}>Add new Person</Button>
                </Col>
            </Row>

        </Container>
    );
};

export default LocationUpdate;
