import React from "react";
import { ListGroup } from "react-bootstrap"

import { EditMaintenanceLogModal } from "./";
import { FormattedDate } from "../";

import * as Constants from '../../utils/constants';

const MaintenanceLogList = ({
    data = [],
    onClick = () => { },
    refreshDataFunc = () => { console.warn("no refresh data func set") },
    hideEdit = false
}) => {
    const [editData, setEditData] = React.useState(null);

    return (
        <>
            <EditMaintenanceLogModal show={editData !== null} data={editData} onHide={() => { refreshDataFunc(); setEditData(null) }} />

            <ListGroup variant="flush" className="panel-list">
                {data != null && data.map((v) => {

                    let editButton = "";
                    if (hideEdit === false) {
                        editButton = <span onClick={() => setEditData(v)}>🖊️</span>
                    }
                    const version = !!v.versionMetrix ? v.versionMetrix : "unknown";
                    return (
                        <ListGroup.Item key={"mlog-" + v.id} onClick={() => onClick(v)}>
                            <div><strong style={{ color: Constants.Map_LogTypeNumeric_Color[v.type] }}>{Constants.Map_LogTypeNumeric_LogTypeString[v.type]}</strong> by <strong>{v.email}</strong> &#8211; <span className="text-muted">MetriX Version: {version} &#8211; {<FormattedDate local format="dd.MM.yyyy HH:mm:ss">{v.createdAt}</FormattedDate>}</span> {editButton}</div>
                            <div>{v.description}</div>
                            {v.resolution !== "" && (
                                <div style={{ display: 'flex' }}>
                                    <span>✔️</span>
                                    <span>{v.resolution}</span>
                                </div>
                            )}
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </>
    )
}

export default MaintenanceLogList;