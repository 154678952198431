import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import * as Constants from '../../utils/constants';
import CenteredSpinner from "../CenteredSpinner";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const WeeklyUsageByLocationChart = ({ data = [], height = 250, xlabel = "X-Label", ylabel = "Y-Label", top = -1, flop = -1 }) => {

  if (!data)
    return <CenteredSpinner />;

  if (top !== -1) 
    data = data.slice(0, Math.min(top, data.length));
  
  if (flop !== -1) 
    data = data.slice(-flop);

  const labels = data.map(item => item.name);
  const datasets = [];

  data.forEach((location, locationIndex) => {
    location.systems.forEach((system, systemIndex) => {
      const datasetIndex = datasets.findIndex(ds => ds.label === system.gateIdString);

      if (datasetIndex === -1) {
        datasets.push({
          label: system.gateIdString, 
          data: new Array(data.length).fill(0), 
          backgroundColor: Constants.PLOT_COLORS[systemIndex % Constants.PLOT_COLORS.length],
          stack: "stacked",
        });
      }

      datasets.find(ds => ds.label === system.gateIdString).data[locationIndex] = system.cnt;
    });
  });

  const chartData = { labels, datasets };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        title: { display: true, text: xlabel },
        ticks: { autoSkip: false, maxRotation: 45, minRotation: 45 },
      },
      y: {
        title: { display: true, text: ylabel },
      },
    },
  };

  return (
    <div style={{ height: height }}>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default WeeklyUsageByLocationChart;
