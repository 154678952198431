import React from "react";
import axios from 'axios';

import * as Constants from '../utils/constants';
import { Button } from "react-bootstrap";

const XpuIdSuggestion = function({onClick=(x) => console.log("selected", x)}) {

    const [nextIds, setNextIds] = React.useState(null)
    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/xpu/nextids')
            .then(res => {
                setNextIds(res.data)
            }).catch(err => console.error(err))
    }, [])

    if(nextIds === null) {
        return <></>
    }

    return (
        <div className="rounded bg-secondary text-white py-1 mb-4 px-3">
            <span style={{fontSize: 44, display: 'block'}} className="float-start">🤖</span>
            <div style={{display: 'block', marginLeft: 72}}>
            <p className="mb-0">Based on all existing XPU IDs, Mecha-Anubis suggests to pick from one of the following (unused) XPU IDs:</p>
            
                {Object.keys(nextIds).map(k => {
                    return <Button onClick={() => onClick(nextIds[k].next)} key={k} variant="light" className="m-1" size="sm">{nextIds[k].next}</Button>
                })}
            
            </div>
        </div>
    )
};

export default XpuIdSuggestion;
