import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import "chartjs-adapter-date-fns";
import { format } from "date-fns";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MessagesPerTimespanChart = ({ data, height = 250 }) => {
  if (!data || data.length === 0) {
    return <p style={{ textAlign: "center", marginTop: 20 }}>No data available</p>;
  }

  const formatAsHourMinute = (timestamp) => format(new Date(timestamp), "HH:mm");

  const labels = data.map((item) => formatAsHourMinute(item.timeblock || (item.block * 1000)));

  const datasets = [];

  if (data.some((item) => item.errors !== undefined)) {
    datasets.push(
      {
        label: "Errors",
        data: data.map((item) => parseInt(item.errors || 0) - parseInt(item.mutedErrors || 0)),
        backgroundColor: "#D00000",
        stack: "stacked",
      },
      {
        label: "Muted Errors",
        data: data.map((item) => parseInt(item.mutedErrors || 0)),
        backgroundColor: "#cc8989",
        stack: "stacked",
      }
    );
  }

  if (data.some((item) => item.Warnings !== undefined || item.warnings !== undefined)) {
    datasets.push(
      {
        label: "Warnings",
        data: data.map((item) => parseInt(item.Warnings || item.warnings || 0) - parseInt(item.mutedWarnings || 0)),
        backgroundColor: "#e6c302",
        stack: "stacked",
      },
      {
        label: "Muted Warnings",
        data: data.map((item) => parseInt(item.mutedWarnings || 0)),
        backgroundColor: "#d9cb8d",
        stack: "stacked",
      }
    );
  }

  if (data.some((item) => item.Info !== undefined)) {
    datasets.push({
      label: "Info",
      data: data.map((item) => item.Info),
      backgroundColor: "#3bba00",
      stack: "stacked",
    });
  }

  const chartData = { labels, datasets };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "bottom" },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        title: { display: true, text: "Time" },
      },
      y: {
        title: { display: true, text: "Count" },
        stacked: true,
      },
    },
  };

  return (
    <div style={{ height }}>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default MessagesPerTimespanChart;
