import React from "react";
import axios from 'axios';
import { Col, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { FormattedDate, AnubisTable } from ".";

import * as LogLevels from '../utils/logLevels';
import * as Constants from '../utils/constants';

const defaultShownPerPage = 50;

const getFetchParams = (fixedSystemId, newTableState) => {
    let params = {
        offset: 0,
        limit: defaultShownPerPage,
        order: "timestamp",
        orderDir: "DESC"
    };

    if (fixedSystemId !== null) {
        params.gateId = fixedSystemId;
    }

    if (newTableState !== null) {
        params.offset = newTableState.pagination.pageIndex * newTableState.pagination.pageSize;
        params.limit = newTableState.pagination.pageSize;

        const gateFilter = newTableState.filters.find(x => x.id === "gateIdString");
        const levelFilter = newTableState.filters.find(x => x.id === "level");
        const loggerFilter = newTableState.filters.find(x => x.id === "logger");

        if (gateFilter) params.gateIdString = gateFilter.value;
        if (levelFilter) params.minLevel = levelFilter.value;
        if (loggerFilter) params.logger = loggerFilter.value;
    }

    return params;
};

const fetchData = (fixedSystemId, setTableProps, newTableState, refreshFunc = null) => {
    const params = getFetchParams(fixedSystemId, newTableState);
    axios.get(Constants.API_BASE + 'api/message', { params: params })
        .then(res => {
            const newState = {
                page: (params.offset / params.limit) + 1,
                data: res.data.rows,
                dataCount: res.data.count
            };

            setTableProps(newState);

            if (refreshFunc != null) {
                refreshFunc();
            }
        })
        .catch(err => {
            console.log(err);
        });
};

const LogMessageList = ({
    fixedSystemId: propFixedSystemId = null,
    hideCustomer = false,
    hideSystemId = false,
    colClassName = "",
    header = null,
    xs = 12,
    inlineTitle = null
}) => {
    const [tableProps, setTableProps] = React.useState({
        page: 1,
        data: null,
        dataCount: 0
    });

    const [refreshIcon, setRefreshIcon] = React.useState(false);

    const fixedSystemId = (!!propFixedSystemId) ? propFixedSystemId : null;

    const fetchCallback = React.useCallback(() => {
        fetchData(fixedSystemId, setTableProps, null);
    }, [fixedSystemId]);

    React.useEffect(() => {
        fetchCallback();
    }, [fetchCallback]);

    const messageFormatter = (row) => {
        if (row.exception === "" || row.exception === null) {
            return row.message;
        } else {
            return (
                <>
                    {row.message} <br /><strong>Exception:</strong><br /><pre>{row.exception}</pre>
                </>
            );
        }
    };

    const columns = [];

    columns.push({
        header: 'Date',
        id: 'createdAt',
        enableColumnFilter: false,
        accessorKey: 'createdAt',
        cell: ({ row }) => (<FormattedDate local format="dd.MM.yy HH:mm:ss">{row.original.createdAt}</FormattedDate>),
        meta: {
            style: {
                width: "110px"
            }
        }
    });

    columns.push({
        header: 'Level',
        accessorKey: 'level',
        cell: ({ row }) => LogLevels.IconFromInt(row.original.level),
        align: "center",
        meta: {
            style: {
                width: "80px"
            },
            filterVariant: "select",
            filterSelectValues: [
                { key: undefined, value: "" },
                { key: 10, value: 'DEBUG' },
                { key: 20, value: 'INFO' },
                { key: 30, value: 'WARN' },
                { key: 40, value: 'ERROR' },
                { key: 50, value: 'FATAL' }
            ]
        }
    });

    if (hideSystemId !== true) {
        columns.push({
            header: 'System',
            accessorKey: 'gateIdString',
            cell: ({ row }) => <Link to={"/systems/show/" + row.original.GateId}>{row.original.Gate.gateId}</Link>,
            meta: {
                style: {
                    width: "80px"
                }
            }
        });

    }

    if (hideCustomer !== true) {
        columns.push({
            header: "Customer",
            enableColumnFilter: false,
            accessorKey: 'Gate.Customer.name',
            meta: {
                style: {
                    width: "110px"
                }
            }
        });
    }

    columns.push({
        header: 'Logger',
        accessorKey: 'logger',
        cell: ({ row }) => `${row.original.logger} (${row.original.version})`,
        meta: {
            style: {
                width: "150px"
            }
        }
    });

    columns.push({
        header: "Message",
        accessorKey: 'message',
        enableColumnFilter: false,
        cell: ({ row }) => messageFormatter(row.original),
    });

    const refresh = async () => {
        if (refreshIcon === false) {
            setRefreshIcon(true);
            fetchData(fixedSystemId, setTableProps, null, () => setRefreshIcon(false));
        }
    };

    return (
        <Col className={colClassName} xs={xs}>
            {header !== null && (
                <h3 style={{ float: "left" }}>
                    {header}
                </h3>
            )}

            {tableProps.data === null ? (
                <Spinner animation="border" style={{ position: "relative", top: "5%", left: "50%", marginTop: "100px" }} />
            ) : (
                <>
                    {inlineTitle != null && (
                        <h2 style={{ marginBottom: 0, marginTop: 8, float: "left" }}>
                            {inlineTitle}
                            {refreshIcon === false ? (
                                <FontAwesomeIcon onClick={refresh} icon={faRefresh} size="sm" color="#00000088" style={{ marginLeft: "8px", cursor: "pointer" }} />
                            ) : (
                                <FontAwesomeIcon icon={faSpinner} size="sm" color="#ff00000" style={{ marginLeft: "8px", cursor: "pointer" }} />
                            )}
                        </h2>
                    )}
                    <AnubisTable
                        data={tableProps.data}
                        columns={columns}

                        paginationEnabled={true}
                        sizePerPage={defaultShownPerPage}

                        remoteFunc={(newTableState) => fetchData(fixedSystemId, setTableProps, newTableState)}
                        remoteControlled={true}
                        remoteCount={tableProps.dataCount}

                        enableFilters={true}
                    />
                </>
            )}
        </Col>
    );
};

export default LogMessageList;
