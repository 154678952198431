import React from "react";
import { Modal, Button } from 'react-bootstrap'

const NotificationModal = ({ data = {
    data: {
        icon: "⚠️",
        title: "Modal Title",
        text: "Some text",
        closeFunc: () => console.warn("cannot close, set data.closeFunc")
    }
} }) => {
    return (
        <Modal
            show={data !== null}
            size="lg"
            backdrop="static"
        >
            <Modal.Body>
                <h1 className="text-center mb-3">{data?.icon}</h1>
                <h3 className="text-center">{data?.title}</h3>
                <p className="text-center mb-0">{data?.text}</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-end">
                <Button onClick={data?.closeFunc}>Ok</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NotificationModal;