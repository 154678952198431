import React from "react";
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap'
import { useForm } from "react-hook-form";

import { NotificationModal } from "../";

import useModal from "../../utils/useModal";
import * as Constants from '../../utils/constants';

function CameraModal({
    data = {
        serialNr: undefined,
        comment: undefined,
        decommissioned: undefined,
    },
    onFinish = () => { },
    onHide = () => { },
    ...subProps
}) {

    const { register, handleSubmit, formState: { errors }, reset } = useForm({ defaultValues: { name: data?.name } })

    const [loading, setLoading] = React.useState(false);
    const {data: notificationData, setData: setNotificationData} = useModal();

    let header = `Update Properties of '${data?.serialNr}'`;

    const resetAndClose = () => {
        setLoading(false)
        reset();
        onFinish();
        onHide()
    }

    const onCancel = () => {
        resetAndClose();
    }

    const onSubmit = data => {
        setLoading(true)

        // no update process
        axios.post(Constants.API_BASE + 'api/camera/' + data.serialNr, data)
            .then(res => resetAndClose())
            .catch(err => { setNotificationData({title: "Update Failed", text: err.message}); setLoading(false); })

    }

    return (
        <>
            <NotificationModal data={notificationData} />
            <Modal
                {...subProps}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <h3 className="text-center">{header}</h3>
                        <Form.Group>
                            <Form.Label>Helpful information for this camera or why it is not used anymore:</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter helpful comment..."
                                {...register("comment", { required: false })}
                                isInvalid={errors?.comment}
                                defaultValue={data?.comment}
                                as="textarea"
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Check
                                type="radio"
                                label="Active: camera is in use."
                                value={0}
                                {...register("decommissioned", { required: false })}
                                defaultChecked={data?.decommissioned === 0}
                            />
                            <Form.Check
                                type="radio"
                                label="Decommissioned: enable if the camera is not used anymore."
                                value={1}
                                {...register("decommissioned", { required: false })}
                                defaultChecked={data?.decommissioned === 1}
                            />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="float-end me-2" type="submit" disabled={loading}>
                            {loading ? 'Loading...' : 'Save'}
                        </Button>

                        <Button className="float-end" onClick={() => onCancel()} disabled={loading}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default CameraModal