import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedDate } from "../";

const TemporalDeltaTable = function ({
    data = null
}) {
    let tableJsx = "";
    if (data === null || data.length === 0) {
        tableJsx = (
            <tr>
                <td colSpan="2" className="text-center">
                    <br />no data<br />
                    <span style={{ fontSize: "300%" }}>🎉</span>
                </td>
            </tr>
        )
    } else {
        tableJsx = data.map((v, index) => {

            return (<tr key={"temporalDelta" + index}>
                <td><Link to={"/systems/show/" + v.id}>{v.systemId}</Link></td>
                <td><FormattedDate local format="dd.MM.yyyy HH:mm:ss">{v.lastMessage}</FormattedDate></td>
                <td>{v.deltaMinutes}</td>
            </tr>)
        })
    }


    return (
        <Table size="sm" responsive>
            <thead>
                <tr>
                    <th style={{ width: '20%' }}>System</th>
                    <th >Last Message</th>
                    <th style={{ width: '30%' }}>Δ Minutes</th>
                </tr>
            </thead>
            <tbody>
                {tableJsx}
            </tbody>
        </Table>
    )
};

export default TemporalDeltaTable;
