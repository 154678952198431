import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Col, Row, Button, Container, Alert } from "react-bootstrap";

import { AnubisTable } from "../../components";

import * as Constants from '../../utils/constants';

const Locations = (props) => {
    const [data, setData] = React.useState(null);
    const [toastDismissed, setToastDismissed] = React.useState(false);

    let toast = "";
    if (!!props.location.state?.toast && toastDismissed === false) {
        toast = (<Alert variant="success" onClose={() => setToastDismissed(true)} dismissible>{props.location.state.toast}</Alert>)
    }

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/customer?withLocation=true')
            .then(res => {
                setData(res.data);
            });
    }, []);

    const editButton = ({ row }) => (
        <>
            <Button as={Link} to={"/locations/edit/" + row.original.id} size="sm" className="me-1">
                Edit
            </Button>
            <Button disabled size="sm">
                Remove
            </Button>
        </>
    );

    const nameFormatter = ({ row }) => (
        <Link to={"/locations/edit/" + row.original.id} size="sm" className="mr-1">
            {row.original.name}
        </Link>
    );

    const columns = React.useMemo(() => [
        {
            header: "Location",
            accessor: "name",
            cell: nameFormatter,
        },
        {
            header: "UN/LOCODE",
            accessor: "locode",
        },
        {
            header: "Actions",
            accessor: "id",
            cell: editButton,
            disableSortBy: true,
            align: 'right',
            meta: {
                style: {
                    width: "130px"
                }
            }
        }
    ], []);

    return (
        <Container>
            <h2 className="my-3 text-center">Costumer Locations</h2>
            {!!toast && (
                <Row>
                    <Col>{toast}</Col>
                </Row>
            )}
            <Row>
                <Col>
                    <p className="text-center">Use this page to manage the existing locations. New locations can be added on the respective customer panel.</p>
                </Col>
            </Row>
            {data !== null && data.map((v, index) => (
                <Row className="mb-3" key={"customer-" + index}>
                    <Col>
                        <h3>{v.name}</h3>
                        <AnubisTable
                            columns={columns}
                            data={v.Locations}
                        />
                    </Col>
                </Row>
            ))}
        </Container>
    );
}

export default Locations;