import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Col, Row, Button, Container, Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { AnubisTable } from "../../components"; 

import * as Constants from '../../utils/constants';

const Downloads = (props) => {
  const [data, setData] = React.useState([]);
  const [toastDismissed, setToastDismissed] = React.useState(false);

  let toast = "";
  if (!!props.location.state?.toast && toastDismissed === false) {
    toast = (<Alert variant="success" onClose={() => setToastDismissed(true)} dismissible>{props.location.state.toast}</Alert>)
  }

  React.useEffect(() => {
    axios.get(Constants.API_BASE + 'api/download')
      .then(res => {
        console.log(res.data)
        setData(res.data);
      })
  }, []);

  const editButton = ({ row }) => {
    return (
      <Button as={Link} to={"/downloads/edit/" + row.original.app} size="sm" className="me-1">
        Edit
      </Button>
    );
  };

  const nameFormatter = ({ row }) => {
    return (
      <Link to={"/downloads/edit/" + row.original.app}>
        {row.original.app}
      </Link>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        header: "App",
        accessorKey: "app",
        cell: nameFormatter,
      },
      {
        header: "Type",
        accessorKey: "type",
      },
      {
        header: "Version",
        accessorKey: "version",
      },
      {
        header: ".Net Version",
        accessorKey: "netVersion",
      },
      {
        header: "Actions",
        cell: editButton,
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <Container>
      <h2 className="my-3 text-center">Software Updates</h2>
      <Helmet>
        <title>Software Updates - Anubis</title>
      </Helmet>
      {!!toast && (
        <Row>
          <Col>{toast}</Col>
        </Row>
      )}
      <Row>
        <Col>
          <p className="text-center">The log server provides software updates directly and indirectly via Google Drive. Which versions are provided is defined on this page.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <AnubisTable columns={columns} data={data} />
        </Col>
      </Row>
    </Container>
  );
};

export default Downloads;