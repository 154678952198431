import React from "react";
import { Container, Row, Col } from "react-bootstrap"

import { SearchPanel, NumberMessages24hPanel, HomeTimetrackingPanel } from "../components";

const Home = function (props) {

  return (
    <Container fluid style={{ marginTop: "8px", marginBottom: "8px" }}>
      <Row >
        <NumberMessages24hPanel />
      </Row>
      <Row>
        <Col xs={12} sm={4} className="panel">
          <h3>SEARCH</h3>
          <SearchPanel />
        </Col>
        <Col className="panel">
          <h3>Add Time Tracking Record</h3>
          <HomeTimetrackingPanel />
        </Col>
        <Col>{/* we need this to align panel boundaries with each other, for whatever reason */}</Col>
      </Row>
    </Container>
  );

}
export default Home;
