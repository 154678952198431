import React from "react";
import axios from 'axios';
import { Container, Row, Col, Form } from "react-bootstrap"

import SystemUsageChart from "../components/graphs/SystemUsageChart";
import SimpleBarChart from "../components/graphs/SimpleBarChart";
import WeeklyUsageByLocationChart from "../components/graphs/WeeklyUsageByLocationChart"

import { useKeycloak } from '../utils/KeycloakHook'
import * as Constants from '../utils/constants';

function getWeeklyUsageByLocation(plotData, locations) {
  const locationMap = new Map();
  if (plotData.scanCounts !== null) {

    plotData.locationCounts.forEach(location => {
      locationMap.set(location.id, { name: location.name, cnt: location.cnt, N: 0, sys0: 0, sys1: 0, sys2: 0, sys3: 0, systems: [] });
      locations.push({ id: location.id, name: location.name });
    });

    plotData.scanCounts.forEach(v => {

      // This system has no location assigned
      if (v.LocationId === null) return;

      // get the location in the locationMap
      const mappedLocation = locationMap.get(v.LocationId);

      mappedLocation.systems.push(v);
    });
  }

  // Convert map to array to make recharts happy
  const locationArray = Array.from(locationMap, ([name, val]) => val);
  return locationArray;
}

const Usage = function (props) {

  const [plotData, setPlotData] = React.useState({ scanCounts: null, binnedScans: null, hourlyThroughput: null, locationCounts: null });

  const [locationUsage, setLocationUsage] = React.useState(null);
  const [locationUsageUpdating, setLocationUsageUpdating] = React.useState(false);

  const { token } = useKeycloak()

  const query = React.useCallback(() => {

    const CancelToken = axios.CancelToken;
    const cancelToken1 = CancelToken.source()

    const usageDataPromise = axios.get(Constants.API_BASE + 'api/usage/usageData', { cancelToken: cancelToken1.token });

    Promise.allSettled([usageDataPromise])
      .then((results) => {
        const usageData = results[0];

        let newPlotData = null;

        if (usageData.status === "fulfilled") {
          newPlotData = usageData.value.data;
        } else {
          console.error(usageData.reason);
        }

        setPlotData(newPlotData);
      })

    return () => {
      cancelToken1.cancel("effect cleanup. cancel token 1")
    }
  }, []);

  // Use effect to query for data
  React.useEffect(() => {
    query();
  }, [query])

  // Locations list for SELECTs
  let locations = []

  // Augment location data
  const locationArray = getWeeklyUsageByLocation(plotData, locations);

  locations.sort((a, b) => a.name.localeCompare(b.name))

  let { sumLast24, sumLastWeek, maxThroughputName, maxThroughput, activeCount } = extractGaugeValues(plotData);

  const updateLocationUsage = (e) => {
    const id = e.target.value;
    if (id === 0) {
      setLocationUsage(null);
    }
    else {
      setLocationUsageUpdating(true)

      const headers = { Authorization: 'Bearer ' + token }
      axios.get(Constants.API_BASE + 'api/usage/usageCountsPerLocation/' + id, { headers: headers })
        .then((results) => {
          setLocationUsage(results.data)
          setLocationUsageUpdating(false)
        })
        .catch(err => alert(err))
    }
  }

  return (
    <Container fluid style={{ marginTop: "8px", marginBottom: "8px" }}>
      <Row >
        <Col xs={12} className="panel">
          <h3>Hourly Global System Usage</h3>
          <div style={{ height: 400 }}>
            <SystemUsageChart data={plotData.binnedScans} secondData={locationUsage} height={50} />
          </div>
          <Form className="flex-row-reverse">
            <Form.Group>
              <Form.Label>Overlay Location Usage:</Form.Label>{' '}
              <Form.Control as="select" size="sm" onChange={(e) => updateLocationUsage(e)} disabled={locationUsageUpdating}>
                <option value="0">none</option>
                {locations.map(v => {
                  return <option value={v.id} key={"location-" + v.id}>{v.name}</option>
                })}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col className="panel panel-gauge text-center">
          <p>Last 24H</p>
          <h2>{sumLast24}</h2>
          <p>Total Measurements</p>
        </Col>

        <Col className="panel panel-gauge text-center">
          <p>Last 7 Days</p>
          <h2>{sumLastWeek}</h2>
          <p>Total Measurements</p>
        </Col>

        <Col className="panel panel-gauge text-center">
          <p>{maxThroughputName}</p>
          <h2>{maxThroughput} <sup>1</sup>/<sub>hour</sub></h2>
          <p>Highest hourly throughput in past 24h</p>
        </Col>

        <Col className="panel panel-gauge text-center">
          <p>Active Systems</p>
          <h2>{activeCount}</h2>
          <p>In the past 7 days</p>
        </Col>
      </Row>

      <Row>
        <Col className="panel" xs={12}>
          <h3>Weekly Usage by Location</h3>
          <WeeklyUsageByLocationChart data={locationArray} xlabeloffsety={40} height={450} ylabel="Number of Measurements" ylabeloffset={0} xlabel="Location" xkey="name" top={40} />
        </Col>
      </Row>

      <Row>
        <Col className="panel" xs={6}>
          <h3>Most used systems in the last 7 days</h3>
          <SimpleBarChart data={plotData.scanCounts} height={350} ylabel="Number of Measurements" xlabel="System" ylabeloffset={0} top={20} />
        </Col>

        <Col className="panel" xs={6}>
          <h3>Least used systems in the last 7 days</h3>
          <SimpleBarChart data={plotData.scanCounts} height={350} ylabel="Number of Measurements" xlabel="System" ylabeloffset={0} flop={20} />
        </Col>
      </Row>

      <Row>
        <Col className="panel">
          <h3>Hourly Throughput per System in the last 24h</h3>
          <SimpleBarChart data={plotData.hourlyThroughput} height={350} top={40} xlabel="System" ylabel="Max. Measurements Within 1 Hour" ylabeloffset={20} />
        </Col>
      </Row>

    </Container>
  );
}
export default Usage;


function extractGaugeValues(plotData) {

  // Start 24h ago, floor to the full hour
  let start = new Date();
  start.setDate(start.getDate() - 1);
  const minTimestamp = Math.floor(start.getTime() / 1000 / 3600) * 3600;

  let sumLast24 = 0;
  let sumLastWeek = 0;
  if (plotData.binnedScans != null) {
    for (let i = 0; i < plotData.binnedScans.length; i++) {

      if (plotData.binnedScans[i].cnt === null)
        plotData.binnedScans[i].cnt = 0;

      // Sum last 24h
      if (plotData.binnedScans[i].block >= minTimestamp) {
        sumLast24 += plotData.binnedScans[i].cnt;
      }

      // Sum complete week
      sumLastWeek += plotData.binnedScans[i].cnt;
    }
  }

  // Get throughput gauge panel variables
  let maxThroughput = 0;
  let maxThroughputName = "?";
  if (plotData.hourlyThroughput != null) {
    maxThroughputName = !!(plotData.hourlyThroughput[0]) ? plotData.hourlyThroughput[0].gateIdString : 'unknown';
    maxThroughput = !!(plotData.hourlyThroughput[0]) ? (plotData.hourlyThroughput[0].cnt).toFixed(1) : 0;
  }

  // Get active gauge panel variables
  let activeCount = "?";
  if (plotData.scanCounts != null) {
    activeCount = plotData.scanCounts.length;
  }
  return { sumLast24, sumLastWeek, maxThroughputName, maxThroughput, activeCount };
}

