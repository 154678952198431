import React from "react";
import axios from 'axios';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useDebounce } from 'use-debounce';

import { MaintenanceLogList } from ".";
import { NotificationModal } from "../";

import useModal from "../../utils/useModal";
import { API_BASE, Map_LogTypeNumeric_LogTypeString, Map_LogTypeString_LogTypeNumeric } from '../../utils/constants';
import { useKeycloak } from '../../utils/KeycloakHook'


function getDateTime() {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    if (month.toString().length === 1) {
        month = '0' + month;
    }
    if (day.toString().length === 1) {
        day = '0' + day;
    }
    if (hour.toString().length === 1) {
        hour = '0' + hour;
    }
    if (minute.toString().length === 1) {
        minute = '0' + minute;
    }
    if (second.toString().length === 1) {
        second = '0' + second;
    }
    var dateTime = year + '-' + month + '-' + day + 'T' + hour + ':' + minute;
    return dateTime;
}

function AddMaintenanceLogModal({
    show = false,
    onChange = () => { },
    onHide = () => { },
    data = null,
    systemId = undefined,
}) {


    const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({ defaultValues: { name: data?.name } })

    const [loading, setLoading] = React.useState(false);
    const [logType, setLogType] = React.useState(undefined);
    const [needle, setNeedle] = React.useState(null);
    const [suggestions, setSuggestions] = React.useState(null);
    const [debouncedSearch] = useDebounce(watch("description"), 500)
    const { data: notificationData, setData: setNotificationData } = useModal();

    const { profile } = useKeycloak();

    React.useEffect(() => {
        if (needle !== null) {
            axios.get(API_BASE + 'api/maintenancelog?search=' + needle)
                .then(res => setSuggestions(res.data))
                .catch(err => console.warn(err))
        }
    }, [needle])

    if (systemId === undefined) {
        alert("no system id defined for add maintenance log modal.")
        return
    }

    const resetAndClose = () => {
        setLoading(false)
        setLogType(undefined)
        reset();
        onChange();
        onHide()
    }

    const onCancel = () => {
        resetAndClose();
    }

    const onSubmit = async formData => {

        if (logType === undefined) {
            alert("Log Type must be defined.")
            return;
        }

        formData.timestampUtc = new Date(formData.timestamp)
        setLoading(true)
        try {
            formData.uid = profile.sub;
            formData.email = profile.email;
            formData.GateId = systemId;
            formData.logType = Map_LogTypeString_LogTypeNumeric[logType]
            await axios.post(API_BASE + 'api/maintenancelog', formData)

            resetAndClose();
        } catch (err) {
            setNotificationData({ title: "Update Failed", text: err.message })
            setLoading(false);
        }
    }

    const onSelectExisting = async (dbData) => {
        setValue('description', dbData.description)
        setValue('resolution', dbData.resolution)
        setLogType(Map_LogTypeNumeric_LogTypeString[dbData.type])
    }

    if (debouncedSearch !== undefined && debouncedSearch.length > 3 && debouncedSearch !== needle) {
        setNeedle(debouncedSearch);
    }

    return (
        <>
            <NotificationModal data={notificationData} />
            <Modal
                show={show}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <h3 className="text-center">Add Entry to Maintenance Log</h3>

                        <Row>
                            <Col>
                                <h4 className="text-center">Create new record</h4>
                                <Form.Group className="mb-2 text-center">
                                    <Form.Label>Type:</Form.Label>
                                    <Button variant={logType === "metrix" ? "primary" : "secondary"} size="sm" className="me-2 ms-2" onClick={() => setLogType("metrix")}>MetriX</Button>
                                    <Button variant={logType === "system" ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType("system")}>System</Button>
                                    <Button variant={logType === "camera" ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType("camera")}>Camera</Button>
                                    <Button variant={logType === "scale" ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType("scale")}>Scale</Button>
                                    <Button variant={logType === "barcode" ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType("barcode")}>Barcode</Button>
                                    <Button variant={logType === "config" ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType("config")}>Configuration</Button>
                                    <Button variant={logType === "general" ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType("general")}>General</Button>
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>Date *</Form.Label>
                                    <Form.Control type="datetime-local" rows="3" placeholder="Date" {...register("timestamp", { required: true })} isInvalid={errors?.timestamp} defaultValue={getDateTime()} />
                                    <Form.Control.Feedback type="invalid">Date and time is required.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>Description *</Form.Label>
                                    <Form.Control as="textarea" rows="3" placeholder="Description of the issue / the modifications" {...register("description", { required: true })} isInvalid={errors?.description} />
                                    <Form.Control.Feedback type="invalid">The description is required.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>Resolution</Form.Label>
                                    <Form.Control as="textarea" rows="3" placeholder="Optional text that describes the solution to this issue" {...register("resolution")} isInvalid={errors?.resolution} />
                                    <Form.Control.Feedback type="invalid">Please enter an e-mail</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>Effort in Minutes*</Form.Label>
                                    <Form.Control as="input" rows="3" placeholder="Effort in minutes" {...register("effortMinutes", { required: true })} isInvalid={errors?.effortMinutes} />
                                    <Form.Control.Feedback type="invalid">Please estimate the effort in hours.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-2 text-center">
                                    <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 5)}>5&thinsp;min</Button>
                                    <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 10)}>10&thinsp;min</Button>
                                    <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 15)}>15&thinsp;min</Button>
                                    <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 20)}>20&thinsp;min</Button>
                                    <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 30)}>30&thinsp;min</Button>
                                    <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 45)}>45&thinsp;min</Button>
                                    <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 60)}>60&thinsp;min</Button>
                                </Form.Group>
                            </Col>
                            <Col className="border-left">
                                <h4 className="text-center">Reuse existing log entries</h4>
                                <p className="text-center">Start entering a description to generate suggestions. Then, select a fitting log entry to copy its contents into the form on the left side.</p>
                                {suggestions != null && (
                                    <MaintenanceLogList data={suggestions} onClick={(item) => onSelectExisting(item)} hideEdit={true} />
                                )}
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="float-end" onClick={() => onCancel()} disabled={loading}>
                            Cancel
                        </Button>

                        <Button className="float-end me-2" type="submit" disabled={loading}>
                            {loading ? 'Loading...' : 'Add Log Entry'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default AddMaintenanceLogModal;