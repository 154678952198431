import React from "react";
import { Container, Table, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormattedDate from "../FormattedDate";

const MaintenanceServiceExpiration = function ({
    data = null,
    rowKey = "some-key",
    dataKey = "cnt",
}
) {

    return (
        <Container fluid className="mb-5">
            <Row>
                <Col xs={12} sm={12} className="panel">
                    <h3>Service Information</h3>
                    <p>
                        This page lists all systems whose service expiration date may require attention. The left table lists all
                        systems with expirated service dates. The right column shows all systems for which no expiration date has
                        been entered at all.
                    </p>
                    <p>
                        Ideally, both lists should be empty. To get rid of systems, either:
                    </p>
                    <ul>
                        <li>Update the service expiration date in the system's settings.</li>
                        <li>Assign development systems to customer Metrilus. All systems which belong to Metrilus are ignored for this evaluation.</li>
                        <li>Decommission systems: inactive systems are also ignored in this evaluation.</li>
                    </ul>
                </Col>
            </Row>

            <Row>
                <Col xs={12} sm={6} className="panel">
                    <h3>Expired Service</h3>
                    <Table size="sm" responsive>
                        <thead>
                            <tr>
                                <th>System</th>
                                <th>Customer</th>
                                <th>Location</th>
                                <th>Expiration Date</th>
                                <th>Last Seen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.expiredService.map((v, i) => {
                                return (
                                    <tr key={"expired-"+i}>
                                        <td><Link to={"/systems/show/" + v.id}><code>{v.gateId}</code></Link></td>
                                        <td>{v.customerName}</td>
                                        <td>{v.locationName}</td>
                                        <td>{v.serviceExpires} ({v.passedDays} days)</td>
                                        <td><FormattedDate local format="dd.MM.yyyy">{v.lastMessage}</FormattedDate></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
                <Col xs={12} sm={6} className="panel">
                    <h3>No Service Expiration Information</h3>
                    <Table size="sm" responsive>
                        <thead>
                            <tr>
                                <th>System</th>
                                <th>Customer</th>
                                <th>Location</th>
                                <th>Last Seen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.noServiceDate.map((v, i) => {

                                return (
                                    <tr>
                                        <td><Link to={"/systems/show/" + v.id}><code>{v.gateId}</code></Link></td>
                                        <td>{v.customerName}</td>
                                        <td>{v.locationName}</td>
                                        <td><FormattedDate local format="dd.MM.yyyy">{v.lastMessage}</FormattedDate></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
};

export default MaintenanceServiceExpiration;
