import React from "react";
import { Switch } from "react-router-dom";
import Keycloak from 'keycloak-js';

import { TopBar, PrivateRoute, LoadingWithLogo } from "./components";

import { Home, Logs, Usage, Maintenance, Profile } from "./views";
import { SystemList, SystemUpdate, SystemShow, SystemDelete, SystemCreate, SystemDocumentation } from './views/systems'
import { CustomerList, CustomerShow, CustomerUpdate, CustomerCreate, CustomerDelete } from './views/customers'
import { TimetrackCreate, TimetrackUpdate, TimetrackDelete } from "./views/timetrack/"
import { Locations, LocationUpdate } from "./views/locations"
import { XpuList, XpuCreate, XpuEdit } from './views/xpus'
import { Downloads, DownloadUpdate } from "./views/downloads";
import { AuthFile, ProductionSheetsPreview, ProductionSheets, InitSystem } from "./views/production";


import { KeycloakProvider } from './utils/KeycloakHook'

import "./app.css";

const kcConfig = {
  "realm": "metrilus",
  "url": "https://auth.metrilus.de/",
  "ssl-required": "external",
  "resource": "logserver-ui",
  "public-client": true,
  "verify-token-audience": true,
  "use-resource-role-mappings": true,
  "confidential-port": 0,
  clientId: "logserver-ui"
}

const keycloak = new Keycloak(kcConfig);



const App = () => {

  return (
    <div id="app">
      <KeycloakProvider keycloak={keycloak} loading={<LoadingWithLogo />}>
        <div id="all">

          <TopBar />

          <div id="page-content-wrapper">
            <Switch>
              <PrivateRoute path="/" exact component={Home} />
              <PrivateRoute path="/logs" exact component={Logs} />

              <PrivateRoute path="/usage" component={Usage} />
              <PrivateRoute path="/maintenance" component={Maintenance} />

              <PrivateRoute exact path="/customers" component={CustomerList} />
              <PrivateRoute path="/customers/show/:id" component={CustomerShow} />
              <PrivateRoute path="/customers/delete/:id" component={CustomerDelete} />
              <PrivateRoute path="/customers/create" component={CustomerCreate} />
              <PrivateRoute path="/customers/edit/:id" component={CustomerUpdate} />

              <PrivateRoute path="/timetrack/create" component={TimetrackCreate} />
              <PrivateRoute path="/timetrack/edit/:id" component={TimetrackUpdate} />
              <PrivateRoute path="/timetrack/delete/:id" component={TimetrackDelete} />

              <PrivateRoute exact path="/systems" component={SystemList} />
              <PrivateRoute exact path="/systems/create" component={SystemCreate} />
              <PrivateRoute exact path="/systems/show/:id" component={SystemShow} />
              <PrivateRoute path="/systems/delete/:id" component={SystemDelete} />
              <PrivateRoute path="/systems/edit/:id" component={SystemUpdate} />
              <PrivateRoute path="/systems/documentation/:id" component={SystemDocumentation} />

              <PrivateRoute exact path="/locations" component={Locations} />
              <PrivateRoute exact path="/locations/edit/:id" component={LocationUpdate} />

              <PrivateRoute exact path="/xpus" component={XpuList} />
              <PrivateRoute exact path="/xpus/create" component={XpuCreate} />
              <PrivateRoute exact path="/xpus/edit/:id" component={XpuEdit} />

              <PrivateRoute exact path="/production/initSystem" component={InitSystem} role="logserver-backend-user-production" />
              <PrivateRoute exact path="/production/createSheets" component={ProductionSheets} role="logserver-backend-user-production" />
              <PrivateRoute exact path="/production/createSheetPreview/:ticketId" component={ProductionSheetsPreview} role="logserver-backend-user-production" />
              <PrivateRoute exact path="/production/createAuth" component={AuthFile} role="logserver-backend-user-production" />

              <PrivateRoute exact path="/downloads" component={Downloads} />
              <PrivateRoute exact path="/downloads/edit/:app" component={DownloadUpdate} />

              <PrivateRoute exact path="/profile" component={Profile} />
            </Switch>
          </div>
        </div>
      </KeycloakProvider>
    </div>
  );
};

export default App;
