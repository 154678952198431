import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

const CustomerFormComponent = function(props) {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => props.saveHandler(data);

    return (

            <Row>
                <Col>
                    <h2 className="text-center">Edit Customer</h2>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label>Customer Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter customer name..." {...register("name", { required: true })} isInvalid={errors?.name} defaultValue={props?.default?.name && props?.default?.name} />
                            <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group>
                            <Form.Label>Notification Level</Form.Label>
                            <Form.Control as="select" {...register("notificationLevel", { required: true })} defaultValue={(props?.default?.notificationLevel) ? props.default.notificationLevel : "20"}>
                                <option value="10">DEBUG</option>
                                <option value="20">INFO</option>
                                <option value="30">WARN</option>
                                <option value="40">ERROR</option>
                                <option value="50">FATAL</option>
                                <option value="99">NONE</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please select a level</Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group>
                            <Form.Label>Comment</Form.Label>
                            <Form.Control as="textarea" rows="3" {...register("comment")} placeholder="Enter an optional comment..."  defaultValue={props?.default?.comment && props?.default?.comment} />
                        </Form.Group>
                        <Button variant="primary" className="float-end mt-1" type="submit">
                            Save
                        </Button>
                    </Form>
                </Col>
            </Row>
  );
};

export default CustomerFormComponent;
