import React from "react";
import axios from 'axios';
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import { AnubisTable, SubNavigation } from "../../components";

import * as Constants from '../../utils/constants';
import { productionNavStates, productionNavTitle } from "../../utils/navStatesProduction";

const editButton = (row, ticketBaseUrl) => (
  <>
    <Link to={{ pathname: ticketBaseUrl + row.original.hs_object_id }} size="sm" className="me-1" target='_blank' rel='noreferrer'>
      Open in Hubspot
    </Link>
    <Button size="sm" as={Link} to={"/production/createSheetPreview/" + row.original.hs_object_id}>
      Create Sheets
    </Button>
  </>
);

const nameFormatter = (row, ticketBaseUrl) => {
  return (
    <Link to={{ pathname: ticketBaseUrl + row.original.hs_object_id }} size="sm" target="_blank" >
      {row.original.content}
    </Link>
  )
};

const ProductionSheets = () => {
  const [data, setData] = React.useState(null);
  const [toast, setToast] = React.useState(null);

  React.useEffect(() => {
    axios.get(Constants.API_BASE + 'api/production/tickets')
      .then(res => {
        const newData = {
          ticketBaseUrl: res.data.ticketBaseUrl,
          tickets: res.data.results.map(d => d.properties)
        };
        setData(newData);
      });
  }, []);




  const columns = React.useMemo(() => [
    {
      header: "Production Ticket",
      id: "content",
      cell: ({ row }) => nameFormatter(row, data.ticketBaseUrl),
    },
    {
      header: "Actions",
      id: "id",
      cell: ({ row }) => editButton(row, data.ticketBaseUrl),
      disableSortBy: true,
      meta: {
        align: 'right'
      },
      headerStyle: { width: "250px" },
    }
  ], [data]);

  return (
    <>
      <SubNavigation pages={productionNavStates} title={productionNavTitle} />
      <Container>
        <h2 className="my-3 text-center">Generate Production Sheets</h2>
        <Row>
          <Col>
            <p>Production sheets can be created for all Orders/Deals with Service/Production tickets in the "Waiting for Production" column. By pressing the "Create Sheets" button, you will create the following documents:</p>
            <ul>
              <li>Production Sheet</li>
              <li>Packaging List</li>
              <li>Order Confirmation</li>
              <li>Bizerba Production Sheet / Scale Order</li>
            </ul>
            <p>It is possible to recreate these documents by re-running the process for tickets. <strong>Warning: this may create duplicate files on Google Drive</strong></p>

            {toast && (
              <Alert variant={toast.variant} onClose={() => setToast(null)} dismissible closeLabel="Close">
                {toast.message}
              </Alert>
            )}
          </Col>
        </Row>

        <Col>
          <Row>
            {data === null ? "loading data from Hubspot..." : (
              <AnubisTable columns={columns} data={data.tickets} />
            )}
          </Row>
        </Col>
      </Container>
    </>
  );
};

export default ProductionSheets;