import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";

import App from "./App";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";

const domNode = document.getElementById("root")
const root = createRoot(domNode)

root.render(
  <Router>
    <Helmet>
      <title>Anubis</title>
    </Helmet>
    <App />
  </Router>
);
