import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Col, Row, Button, Container, Alert } from "react-bootstrap";

import { AnubisTable, FormattedDate, LoadingSpinner } from "../../components";

import * as Constants from '../../utils/constants';

const XpuList = (props) => {

    const [data, setData] = React.useState(null);
    const [toastDismissed, setToastDismissed] = React.useState(false);

    let toast = "";
    if (!!props.location.state?.toast && toastDismissed === false) {
        toast = (<Alert variant="success" onClose={() => setToastDismissed(true)}>{props.location.state.toast}</Alert>)
    }

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/xpu')
            .then(res => {
                setData(res.data);
            });
    }, []);

    const columns = React.useMemo(
        () => [
            {
                header: "XPU Id",
                accessorKey: "name",
                cell: ({ row }) => (
                    row.original.decommissioned ?
                        <Link to={"/xpus/edit/" + row.original.id} className="text-danger"><s>{row.original.name}</s></Link> :
                        <Link to={"/xpus/edit/" + row.original.id}>{row.original.name}</Link>
                ),
                meta: {
                    style: {
                        width: "110px"
                    }
                }
            },
            {
                header: "Type",
                accessorKey: "systemType",
                meta: {
                    style: {
                        width: "110px"
                    }
                }
            },
            {
                header: "System Id",
                accessorKey: "Gates",
                filterFn: (row, columnId, filterValue) => {
                    // join all system ids into a string for string matching
                    var systemIdsForXpu = row.original.Gates.map(v => v.gateId).join(" ").toLowerCase()
                    return systemIdsForXpu.includes(filterValue.toLowerCase())
                },
                cell: ({ row }) => (
                    row.original.Gates.map((v, i) => (
                        <span key={"system" + row.original.id + "-" + v.id + "-" + i}>
                            <Link to={"/systems/show/" + v.id}>{v.gateId}</Link><br />
                        </span>
                    ))
                ),
                meta: {
                    style: {
                        width: "110px"
                    }
                }
            },
            {
                header: "Customer",
                accessorKey: "Customers",
                accessor: d => console.log(d),
                cell: ({ row }) => (
                    row.original.Gates.map((v, i) => (
                        <span key={"customer" + row.original.id + "-" + v.id + "-" + i}>
                            {v.Customer.name}<br />
                        </span>
                    ))
                ),
                filterFn: (row, columnId, filterValue) => { return row.original.Gates.map(v => v.Customer.name).join().toLowerCase().includes(filterValue.toLowerCase()) }
            },
            {
                header: "Location",
                accessorKey: "Location",
                cell: ({ row }) => (
                    row.original.Gates.map((v, i) => {
                        const locName = v.Location === null ? "?" : v.Location.name;
                        return <span key={"loc" + row.original.id + "-" + v.id + "-" + i}>{locName}<br /></span>;
                    })
                ),
                filterFn: (row, columnId, filterValue) => { return row.original.Gates.map(v => v.Location === null ? "" : v.Location.name).join().toLowerCase().includes(filterValue.toLowerCase()) }
            },
            {
                header: "Comment",
                accessorKey: "comment",
                cell: ({ row }) => (
                    row.original.comment > 60 ? row.original.comment.substring(0, 57) + "..." : row.original.comment
                ),
            },
            {
                header: "First Seen",
                accessorKey: "FirstSeen",
                cell: ({ row }) => (
                    row.original.Gates.map((v, i) => (
                        <span key={"first" + row.original.id + "-" + v.id + "-" + i}>
                            <FormattedDate local format="dd.MM.yyyy HH:mm:ss">{v.createdAt}</FormattedDate><br />
                        </span>
                    ))
                ),
                enableColumnFilter: false,
                meta: {
                    style: {
                        width: "130px"
                    }
                }
            },
        ],
        []
    );

    if (data === null) return <LoadingSpinner />;


    return (
        <Container>
            <h2 className="my-3 text-center">XPU Management</h2>
            {!!toast && (
                <Row>
                    <Col>{toast}</Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Button as={Link} to="/xpus/create/" size="sm" className="me-1 float-end">
                        Add new XPU manually
                    </Button>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <AnubisTable
                        columns={columns}
                        data={data}
                        paginationEnabled={true}
                        sizePerPage={25}
                        classes="log-table"
                        enableFilters={true}
                    />
                </Col>
            </Row>

        </Container>
    );
}

export default XpuList;