import React from "react";
import axios from 'axios';
import { Button, Row, Col, Table } from 'react-bootstrap'

import { FormattedDate, BinaryDescisionModal } from "../";

import { API_BASE } from "../../utils/constants";
import { useKeycloak } from '../../utils/KeycloakHook'

const RemoteCommandsWrapper = ({
    onCreateKnownCommand = (x) => console.warn("onCreateKnownCommand not defined. Trying to create" + x),
    showButtons = false,
    children
}) => {

    const { keycloak } = useKeycloak();
    const [modalCommand, setModalCommand] = React.useState(null)

    const onCreateRestart = () => {
        setModalCommand("RESTART")
    }

    const modalYes = async () => {
        try {
            await onCreateKnownCommand(modalCommand)
        } catch (e) {
            alert(e)
        }
        setModalCommand(null)
    }

    const modalNo = async () => {
        setModalCommand(null)
    }

    const canUseButtons = keycloak?.resourceAccess["logserver-backend"]?.roles?.includes("logserver-backend-user-configs")

    return (
        <Row>
            <BinaryDescisionModal
                show={modalCommand !== null}
                title="Create Command"
                text={`Would you like to create a ${modalCommand} command?`}
                yes={() => modalYes()}
                no={() => modalNo()}
            />
            <Col>
                <h3 className="text-center mt-3">Remote Commands</h3>
                <p>
                    Remote commands are executed by the MetriX UpdateTask. Running remote commands is part of the regular UpdateTask iterations.
                    Each command is only executed once (see <code>ExecutionDate</code>). Running remote commands requires that the <code>RemoteCommandsAllowed </code>
                    parameter is set to <code>true</code>.
                </p><p>
                    The UpdateTask can execute predefined commands, e.g. <code>RESTART</code> or PowerShell scripts. All command types require a valid signature which
                    can be calculated with the Maintenance Credential Tool. PowerShell scripts additionally require a valid PowerShell signature to allow their
                    execution on remote systems.
                </p>
                {children}
                {(showButtons && canUseButtons) && (
                    <Button onClick={() => onCreateRestart()}>Create RESTART</Button>
                )}
                {(showButtons && !canUseButtons) && (
                    <p><strong>🔒 Information: </strong>You need <code>logserver-backend-user-configs</code> role to create remote commands.</p>
                )}
            </Col>
        </Row>
    )
}


const SystemRemoteCommands = (props) => {

    const [data, setData] = React.useState([])
    const { profile } = useKeycloak();

    const systemId = props.id;

    const updateData = React.useCallback(() => {
        axios.get(API_BASE + 'api/remotecommand/' + props.id)
            .then(res => { setData(res.data); })
            .catch(err => { console.error(err); });
    }, [props.id])

    React.useEffect(() => {
        updateData()
    }, [updateData])

    const onCreateKnownCommand = async (type) => {
        const d = {
            systemId: systemId,
            owner: profile.email,
            commandName: type
        }

        try {
            await axios.post(API_BASE + 'api/remotecommand/addKnownType', d)
        } catch (e) {
            console.warn(e)
            alert(e)
        }

        updateData()
    }

    if (data === null) {
        return <RemoteCommandsWrapper id={props.id} showButtons={false}><p>Loading commands..</p></RemoteCommandsWrapper>
    }

    if (data.length === 0)
        return <RemoteCommandsWrapper id={props.id} showButtons={true} onCreateKnownCommand={onCreateKnownCommand}><p>No commands yet</p></RemoteCommandsWrapper>

    return (
        <RemoteCommandsWrapper id={props.id} showButtons={true} onCreateKnownCommand={onCreateKnownCommand}>
            <Table size="sm" style={{ tableLayout: "fixed" }}>
                <thead>
                    <tr>
                        <th>Command</th>
                        <th style={{ width: "15%" }}>Signature</th>
                        <th style={{ width: "15%" }}>Owner</th>
                        <th style={{ width: "10%" }}>Execution Date</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((v, index) => {
                        return (
                            <tr key={"remoteCmd-" + index}>
                                <td><pre style={{ maxWidth: "100%", padding: "2px", background: "#ddd", marginBottom: 0 }}>{v.command}</pre></td>
                                <td>{v.signature.slice(0, 20)}...</td>
                                <td>{v.owner}</td>
                                <td>{(v.queriedAt === null) ? "not executed yet" : <FormattedDate local format="dd.MM.yyyy HH:mm:ss">{v.queriedAt}</FormattedDate>}</td>
                            </tr>)
                    })}
                </tbody>
            </Table>
        </RemoteCommandsWrapper>
    )
}

export default SystemRemoteCommands;