import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Col, Row, Button, Container, Alert } from "react-bootstrap";
import * as LogLevels from '../../utils/logLevels';
import * as Constants from '../../utils/constants';
import { Helmet } from "react-helmet";
import AnubisTable from "../../components/AnubisTable"; // Import AnubisTable

const CustomerList = (props) => {
    const [data, setData] = React.useState(null);
    const [toastDismissed, setToastDismissed] = React.useState(false);

    let toast = "";
    if (!!props.location.state?.toast && toastDismissed === false) {
        toast = (<Alert variant="success" onClose={() => setToastDismissed(true)} dismissible>{props.location.state.toast}</Alert>)
    }

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/customer')
            .then(res => {
                setData(res.data);
            });
    }, []);

    const editButton = ({ row }) => (
        <>
            <Button as={Link} to={"/customers/edit/" + row.original.id} size="sm" className="me-1">
                Edit
            </Button>
            <Button as={Link} to={"/customers/delete/" + row.original.id} size="sm">
                Remove
            </Button>
        </>
    );

    const nameFormatter = ({ row }) => (
        <Link to={"/customers/show/" + row.original.id} size="sm">
            {row.original.name}
        </Link>
    );

    const columns =  [
        {
            header: "Customer",
            accessorKey: "name",
            cell: nameFormatter,
            headerStyle: { width: "20%" },
        },
        {
            header: "Notification Level",
            accessorKey: 'notificationLevel',
            cell: ({ row }) => LogLevels.StringFromInt(row.original.notificationLevel),
            headerStyle: { width: "15%" },
            enableColumnFilter: false
        },
        {
            header: "Comment",
            accessorKey: 'comment',
            enableColumnFilter: false
        },
        {
            header: "Actions",
            accessorKey: 'id',
            cell: editButton,
            align: 'right',
            headerStyle: { width: "145px" },
            enableColumnFilter: false

        }
    ];

    return (
        <Container>
            <h2 className="my-3 text-center">Customers</h2>
            <Helmet>
                <title>Customers - Anubis</title>
            </Helmet>
            {!!toast && (
                <Row>
                    <Col>{toast}</Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Button className="float-end mb-2" as={Link} to={"/customers/create"} size="sm" >
                        Create Customer
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    {(data === null) ? "loading...." : (
                        <AnubisTable
                            columns={columns}
                            data={data}
                            pagination={true}
                            sizePerPage={10}
                            classes="log-table"
                            enableFilters={true}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}

export default CustomerList;