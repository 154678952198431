import React from "react";
import { Row, Col, Spinner, } from "react-bootstrap"
import CalibrationMetricChart from "./CalibrationMetricChart";
import axios from 'axios';
import * as Constants from '../../utils/constants';

const processCalibData = (data) => {
    let plotData = new Map();
    const serialSet = new Set();

    data.forEach(v => {

        if (!plotData.has(v.calibTime)) plotData.set(v.calibTime, {});

        const o = plotData.get(v.calibTime)

        const fullname = `${v.cameraType} ${v.SerialNr}`

        serialSet.add(fullname)

        o.ts = new Date(v.calibTime).valueOf();
        o[`${fullname} invalid`] = v.invalidPct;
        o[`${fullname} invalidRoi`] = v.invalidRoiPct;
        o[`${fullname} angle`] = (v.cameraAngle === 0) ? undefined : Math.abs(v.cameraAngle);
        o[`failed`] = (v.cameraAngle === 0) ? 1 : undefined;
    })

    return {
        serials: Array.from(serialSet, (key, value) => key),
        plotData: Array.from(plotData, ([key, value]) => value)
    }
}

const CalibrationHistoryRow = ({systemId}) => {

    const [calibrationData, setCalibrationData] = React.useState(null);

    const gId = systemId;

    React.useEffect(() => {
        if(gId === null) return;

        axios.get(Constants.API_BASE + 'api/statistics/calibrationsPerSystem?id=' + gId)
            .then(res => {
                setCalibrationData(processCalibData(res.data));
            })
            .catch(err => {
                console.error(err);
            });
    }, [gId])

    if(gId === null) {
        console.warn("CalibrationHistoryRow: no system id set.")
        return <></>
    }

    return (
        <Row>
            <Col xs={12} sm={6} className="panel">
                <h3 align="center">Calibration: Missing pixels</h3>

                {(calibrationData === null) ? <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} /> : <CalibrationMetricChart data={calibrationData} height={400} rightKey={null} />}
            </Col>

            <Col xs={12} sm={6} className="panel">
                <h3 align="center">Calibration: Floor Angle</h3>

                {(calibrationData === null) ? <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} /> : <CalibrationMetricChart data={calibrationData} height={400} leftKey=" angle" rightKey={null} scatterFailed={true} yLeftLabel="Angle" />}
            </Col>
        </Row>
    );
};

export default CalibrationHistoryRow;
