import React from "react";
import { Container, Row } from "react-bootstrap"

import { LogMessageList } from "../components";

const Logs = () => (
  <Container className="mt-3" fluid>
    <Row>
      <LogMessageList header="Log Messages"/>
      </Row>
  </Container>
);

export default Logs;
