import React from "react";
import { Col, Spinner } from "react-bootstrap";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import TooltipComponent from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import EventsPerTimespanChart from "../graphs/EventsPerTimespanChart";
import * as Constants from "../../utils/constants";

const EventsPerTimespanPanel = ({ height = 380, width = "100%", systemId = null }) => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!systemId) return;

    setLoading(true);
    axios
      .get(`${Constants.API_BASE}api/diag/eventsForSystemForLast7Days?gateId=${systemId}`)
      .then((res) => setData(res.data))
      .catch((err) => {
        console.error(err);
        setData([]);
      })
      .finally(() => setLoading(false));
  }, [systemId]);

  return (
    <Col xs={12} sm={9} className="panel">
      <h3 align="center">
        Events in the last 7 days
        <OverlayTrigger
          overlay={
            <TooltipComponent>
              Colors indicate event type. Events are grouped into 60min windows. Radius reflects event count. Y-Axis position depends only on event type and is chosen arbitrarily for better visualization.
            </TooltipComponent>
          }
          placement="bottom"
        >
          <span>
            <FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginLeft: "8px" }} />
          </span>
        </OverlayTrigger>
      </h3>

      {loading ? (
        <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} />
      ) : (
        <EventsPerTimespanChart data={data} height={height} />
      )}
    </Col>
  );
};

export default EventsPerTimespanPanel;
