import axios from 'axios';
import React from "react";
import { Container, Row, Col, Button, Table, Alert } from 'react-bootstrap'
import * as Constants from '../../utils/constants';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import FormattedDate from '../../components/FormattedDate';

const CustomerShow = function (props) {

    const cId = props?.match?.params?.id;
    const [toastDismissed, setToastDismissed] = React.useState(false)
    const [data, setData] = React.useState(null);
    const [timetracks, setTimetracks] = React.useState([])

    let toast = "";
    if (!!props.location.state?.toast && toastDismissed === false) {
        toast = (<Alert variant="success" onClose={() => setToastDismissed(true)} dismissible>{props.location.state.toast}</Alert>)
    }

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/customer/' + cId).then(res => setData(res.data)).catch(err => console.error(err))
        axios.get(Constants.API_BASE + 'api/timetrack?customerId=' + cId).then(res => setTimetracks(res.data)).catch(err => console.error(err))
    }, [cId])

    if (!cId) {
        console.error("Customer ID is not set.")
        return "customer id is not set";
    }

    if (data === null) {
        return "loading...";
    }

    return (
        <Container className="mt-3">
            {!!toast && (
                <Row>
                    <Col>{toast}</Col>
                </Row>
            )}
            <Helmet>
                <title>{data === null ? "Loading" : data.name} - Anubis</title>
            </Helmet>
            <Row>
                <Col>
                    <h2 className="text-center mt-3">{data.name}</h2>
                    <Button as={Link} to={"/customers/edit/" + cId} className="float-end clearfix mb-3">
                        Edit Customer
                    </Button>
                    <p>
                        <strong>Comment:</strong><br />
                        {(data.comment === "") ? <i>No comment set yet.</i> : data.comment}
                    </p>
                    <h3 className="text-center">
                        Time Tracking
                    </h3>
                    <p className='text-center'>Use time tracking to log time spent for meetings and actions related to the customer, e.g. the initial setup of a system or consulting regarding potential workflow adjustments. For all maintenance tasks, use the system specific maintenance logs.</p>
                    <Button as={Link} to={"/timetrack/create?customerId=" + cId} className="float-end clearfix mb-3">
                        Add Tracking Record
                    </Button>
                    <div style={{ clear: "both" }}></div>
                    <Table size="sm" responsive>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Person</th>
                                <th>Project</th>
                                <th>Type</th>
                                <th>Description</th>
                                <th>Time</th>
                                <th className="text-end">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {timetracks.length === 0 ? (
                                <tr>
                                    <td className="text-center p-4" colSpan={7}>😎 No need to hold this customer's hands 😎</td>
                                </tr>
                            ) : (
                                timetracks.map((v, i) =>
                                    <tr key={'tr' + i}>
                                        <td><FormattedDate local format="dd.MM.yyyy">{v.meetingTime}</FormattedDate></td>
                                        <td>{v.email}</td>
                                        <td>{v.project}</td>
                                        <td>{Constants.Map_TimetrackTypeNumeric_TimetrackTypeString[v.type]}</td>
                                        <td>{v.description === "" ? <span>-</span> : v.description}</td>
                                        <td>{v.effortMinutes}</td>
                                        <td className="text-end">
                                            <Button as={Link} to={"/timetrack/edit/" + v.id + "?customerId=" + v.CustomerId} size="sm" className='me-1'>Edit</Button>
                                            <Button as={Link} to={"/timetrack/delete/" + v.id + "?customerId=" + v.CustomerId} size="sm">Delete</Button>
                                        </td>
                                    </tr>
                                ))}


                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row>
                <Col>


                </Col>
            </Row>
        </Container>
    );
};

export default CustomerShow;
