import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Col, Row, Button, Container, Alert } from "react-bootstrap";
import { differenceInHours, parseISO } from 'date-fns';
import { Helmet } from "react-helmet";

import { AnubisTable, FormattedDate, LoadingSpinner } from "../../components/";

import * as LogLevels from '../../utils/logLevels';
import * as Constants from '../../utils/constants';

const EditButton = function ({row}) {
    return (
        <>
            <Button as={Link} to={"/systems/edit/" + row.original.id} size="sm" className="me-1">
                Edit
            </Button>
            <Button as={Link} to={"/systems/delete/" + row.original.id} size="sm">
                Remove
            </Button>
        </>
    )
}

const FormatStatus = function (lastMessage) {
    if (!!lastMessage === false)
        return "⬛";

    let t = differenceInHours(new Date(), parseISO(lastMessage));

    if (t < 24) return "🟩"
    else if (t < 48) return "🟨"
    else return "🟥"
}

const FormatLocation = (location) => {
    if (location == null) return <span style={{ opacity: 0.5 }}>not defined</span>
    return location.name
}

const FormatSystemId = (systemId, id, mutedUntil, lastMessage) => {
    let mute = ''
    if (mutedUntil !== null) {
        const refDate = new Date()
        refDate.setHours(0, 0, 0, 0);
        const dbDate = new Date(mutedUntil)
        dbDate.setHours(0, 0, 0, 0);
        const mutedPassed = (dbDate < refDate)
        if (!mutedPassed) mute = '🔇'
    }
    return <Link to={"/systems/show/" + id}>{FormatStatus(lastMessage)} {systemId}{mute}</Link>
}

const SystemList = (props) => {

    const [systemData, setSystemData] = React.useState(null)
    const [toastDismissed, setToastDismissed] = React.useState(false)

    let toast = "";
    if (!!props.location.state?.toast && toastDismissed === false) {
        toast = (<Alert variant="success" onClose={() => setToastDismissed(true)} dismissible>{props.location.state.toast}</Alert>)
    }

    const fetchDataCallback = React.useCallback((newState = null) => {
        let params = {};

        if (newState !== null) {
            if (!!newState?.filters?.gateId) {
                params.gateId = newState.filters.gateId.filterVal;
            }
        }

        Promise.allSettled([
            axios.get(Constants.API_BASE + 'api/gate', { params: params }),
            axios.get(Constants.API_BASE + 'api/camera'),
            axios.get(Constants.API_BASE + 'api/customer'),
            axios.get(Constants.API_BASE + 'api/location')
        ])
            .then((results) => {
                const camDict = {}

                results[1].value.data.forEach(d => {
                    if (Object.hasOwn(camDict, d.GateId)) {
                        camDict[d.GateId].push(d)
                    } else {
                        camDict[d.GateId] = [d]
                    }

                })

                const customers = [{ key: undefined, value: "" }, ...(results[2].value.data.map(i => { return { key: i.name, value: i.name } }))]
                const locations = [{ key: undefined, value: "" }, ...(results[3].value.data.map(i => { return { key: i.name, value: i.name } }))]

                setSystemData({ list: results[0].value.data, cameras: camDict, customers: customers, locations: locations })
            })

    }, []);

    React.useEffect(() => {
        fetchDataCallback();
    }, [fetchDataCallback])

    const GetCameraTypes = (row) => {
        const knownCams = systemData.cameras[row.original.id];
        const joinChars = ", ";

        if (!!knownCams) {
            let types = knownCams.map(k => { return k.cameraType === null ? "❔" : k.cameraType }).join(joinChars)
            return <span>{types}</span>
        } else {
            return <span style={{ opacity: 0.5 }}>No calibration data</span>
        }
    }


    if(systemData === null)
        return <LoadingSpinner />

    const columns = [
        {
            header: 'System ID',
            accessorKey: 'gateId',
            cell: ({ row }) => FormatSystemId(row.original.gateId, row.original.id, row.original.mutedUntil, row.original.lastMessage),
            meta: {
                style: {
                    width: "110px"
                }
            }
        },
        {
            header: "Level",
            accessorKey: 'notificationLevel',
            cell: ({ row }) => LogLevels.GetEffectiveLogLevelString(row.original),
            headerStyle: { width: "75px" },
            enableColumnFilter: false,
            meta: {
                style: {
                    width: "110px"
                }
            }
        },
        {
            header: "Cameras",
            accessorKey: 'Cameras',
            cell: ({ row }) => GetCameraTypes(row),
            enableColumnFilter: false
        },
        {
            header: "Customer",
            accessorKey: 'Customer.name',
            meta: {
                filterVariant: "select",
                filterSelectValues: systemData.customers
            }
        },
        {
            header: "Location",
            accessorKey: 'Location.name',
            cell: ({ value, row }) => FormatLocation(row.original.Location),
            meta: {
                filterVariant: "select",
                filterSelectValues: systemData.locations
            }
        },
        {
            header: "MetriX",
            accessorKey: 'versionMetrix',
            headerStyle: { width: "80px" },
            enableColumnFilter: false,
            meta: {
                style: {
                    width: "110px"
                }
            }
        },
        {
            header: "Last Message",
            accessorKey: 'lastMessage',
            cell: ({ getValue }) => <FormattedDate local format="dd.MM.yyyy HH:mm:ss">{getValue()}</FormattedDate>,
            headerStyle: { width: "130px" },
            enableColumnFilter: false,
            meta: {
                style: {
                    width: "130px"
                }
            }
        },
        {
            header: "Actions",
            accessorKey: 'id',
            cell: EditButton,
            disableSortBy: true,
            align: 'right',
            headerStyle: { width: "135px" },
            enableColumnFilter: false,
            meta: {
                style: {
                    width: "130px"
                }
            }
        }
    ];

    return (
        <Container fluid>
            <h2 className="my-3 text-center">All Systems</h2>
            <Helmet>
                <title>Systems - Anubis</title>
            </Helmet>
            {!!toast && (
                <Row>
                    <Col>{toast}</Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Button className="float-end mb-2" as={Link} to={"/systems/create"} size="sm" >
                        Create new System manually
                    </Button>
                </Col>
            </Row>
            <Row style={{ justifyContent: "center" }}>
                <p>Status: last log message received within the last 24 hours <span style={{ marginRight: "0.25em", fontSize: "80%" }}>🟩</span>, 48 hours <span style={{ marginRight: "0.25em", fontSize: "80%" }}>🟨</span> or more <span style={{ marginRight: "0.25em", fontSize: "80%" }}>🟥</span></p>
            </Row>
            <Row>
                <Col>
                    {(systemData === null) ? "loading...." : (
                        <AnubisTable
                            columns={columns}
                            data={systemData.list}
                            paginationEnabled={true}
                            sizePerPage={25}
                            classes="log-table"
                            enableFilters={true}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}

export default SystemList;