import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import * as Constants from '../../utils/constants';
import CenteredSpinner from "../CenteredSpinner";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SimpleBarChart = ({
  data = [],
  height = 250,
  xlabel = "X-Label",
  xkey = "gateIdString",
  ylabel = "Y-Label",
  ykey = "cnt",
  fill = Constants.PLOT_COLORS[0],
  top = -1,
  flop = -1
}) => {

  if (data == null)
    return <CenteredSpinner />

  if (top !== -1) {
    data = data.slice(0, Math.min(top, data.length));
  }
  if (flop !== -1) {
    data = data.slice(-flop);
  }

  const labels = data.map(item => item[xkey]);
  const dataset = {
    label: ylabel,
    data: data.map(item => item[ykey]),
    backgroundColor: fill,
  };

  const chartData = { labels, datasets: [dataset] };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        title: { display: true, text: xlabel },
        ticks: { autoSkip: false, maxRotation: 45, minRotation: 45 },
      },
      y: {
        title: { display: true, text: ylabel },
      },
    },
  };

  return (
    <div style={{ height: height, minHeight: 100 }}>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default SimpleBarChart;
