import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Table, Alert, Spinner, Button } from 'react-bootstrap';
import { parseISO, format } from 'date-fns'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { decode } from 'html-entities';

import { SubNavigation, NotificationModal } from '../../components';
import SetupAssignment from '../../components/production/SetupAssignment';

import { productionNavStates, productionNavTitle } from "../../utils/navStatesProduction";
import * as Constants from '../../utils/constants';

const dangerousItemsList = [
  { group: 'accessory', sku: '3P-CCP' },
  { group: 'screen', sku: '3P-2SK' },
  { group: 'accessory', sku: '3P-DTM' },
  { group: 'accessory', sku: '3P-DCG' },
  { group: 'barcode', sku: '3P-BS' },
  { group: 'barcode', sku: '3P-BS-WL-SR' },
  { group: 'accessory', sku: '3P-BP-140' },
  { group: 'accessory', sku: '3P-BP-110' },
  { group: 'hardware', sku: 'HW-L-NSP' }
]

function generateAssignmentData(data) {
  if (data !== null) {
    const systems = data.hs.system_id_s_.split(',').map(v => v.trim());

    const totalSystems = Object.entries(data.includedSystems)
      .filter(([key]) => key !== 'HW-L-NSP') // Exclude keys that are 'HW-L-NSP'
      .reduce((sum, [, value]) => sum + parseInt(value), 0);

    const totalNsp = 'HW-L-NSP' in data.includedSystems ? data.includedSystems['HW-L-NSP'] : 0
    const totalBaseSystems = Object.values(data.includedSystems).reduce((a, b) => a + parseInt(b), 0) - totalNsp;

    const totalBase = [];
    for (const [t, n] of Object.entries(data.includedSystems)) {
      for (let j = 0; j < n; j++)
        totalBase.push(t);
    }

    const totalExtensions = [];
    for (const [t, n] of Object.entries(data.includedExtensions)) {
      for (let j = 0; j < n; j++)
        totalExtensions.push(t);
    }

    const totalScales = [];
    for (const [t, n] of Object.entries(data.includedScales)) {
      for (let j = 0; j < n; j++)
        totalScales.push(t);
    }

    const totalRamps = [];
    for (const [t, n] of Object.entries(data.includedRamps)) {
      for (let j = 0; j < n; j++)
        totalRamps.push(t);
    }

    for (let j = totalExtensions.length; j < totalSystems; j++)
      totalExtensions.push("None");

    for (let j = totalScales.length; j < totalSystems; j++)
      totalScales.push("None");

    for (let j = totalRamps.length; j < totalSystems; j++)
      totalRamps.push("None");

    return {
      totalNsp: totalNsp,
      totalBaseSystems: totalBaseSystems,
      systems: systems,
      selectableBase: totalBase,
      selectableExtensions: totalExtensions,
      selectableScales: totalScales,
      selectableRamps: totalRamps,
      initial: systems.map((systemId, index) => ({
        systemId,
        baseType: totalBase[index],
        setupType: '',
        scaleType: '',
        rampType: ''
      }))
    };
  }
  return null
}

function lexOfficeTokens(key, lang) {
  const lut = {
    "deliveryTermsDAP": {
      "US": "Delivery Terms: DAP",
      "DE": "Lieferbedinungen: DAP"
    },
    "deliveryTermsDDP": {
      "US": "Delivery Terms: DDP",
      "DE": "Lieferbedinungen: DDP"
    },
    "deliveryTermsEXW": {
      "US": "Delivery Terms: EXP",
      "DE": "Lieferbedinungen: EXP"
    },
    "deliveryTerms": {
      "US": "General delivery terms apply.",
      "DE": "Es gelten die allgemeinen Lieferbedingungen."
    },
    "shipmentDate": {
      "US": "Shipment Date:",
      "DE": "Versanddatum:"
    },
    "shipmentDateFormat": {
      "US": "yyyy-MM-dd",
      "DE": "dd.MM.yyyy"
    }
  }
  return lut[key][lang]
}

function lexOfficeMapValues(incoterms) {
  const langKey = "US"

  let mappedDeliveryTerms = "not specified - original text to be defined"
  if (incoterms === "DAP") {
    mappedDeliveryTerms = lexOfficeTokens("deliveryTermsDAP", langKey)
  } else if (incoterms === "DDP") {
    mappedDeliveryTerms = lexOfficeTokens("deliveryTermsDDP", langKey)
  } else if (incoterms === "EXW") {
    mappedDeliveryTerms = lexOfficeTokens("deliveryTermsEXW", langKey)
  }

  return {
    mappedDeliveryTerms: mappedDeliveryTerms,
    shipmentDate: lexOfficeTokens("shipmentDate", langKey),
    shipmentDateFormat: lexOfficeTokens("shipmentDateFormat", langKey)
  }

}

function createDangerousItemsList(data, dangerousItemsList) {
  const out = { items: [], screen: "-", accessory: "-", barcode: "-" }
  for (var index in data.data.hs.products) {

    const product = data.data.hs.products[index];

    const danger = dangerousItemsList.find(x => x.sku === product.hs_sku);

    if (danger != null) {
      // Find in line items to get quantity
      const lineItem = data.data.hs.lineItems.find(x => x.hs_product_id === product.hs_object_id);
      out.items.push({
        "name": product.name,
        "sku": product.hs_sku,
        "quantity": lineItem.quantity
      });

      out[danger.group] = "CHECK ORDER!"
    }
  }
  return out;
}

function bizerbaScaleLookup(sku) {
  if (sku === "3P-FS-B-150/150-1500")
    return {
      model: "iL Economy 4000 + iS20",
      size: "150x150",
      accuracy: "500g",
      range: "1500kg"
    }
  else if (sku === "3P-FS-B-150/150-3000")
    return {
      model: "iL Economy 4000 + iS20",
      size: "150x150",
      accuracy: "1000g",
      range: "3000kg"
    }
  else if (sku === "I-HA-L130-R")
    return {
      model: "Rollertop L13x + iS20",
      size: "135x79",
      accuracy: "20g",
      range: "0,4-60kg"
    }
  else
    return {
      model: "unknown",
      size: "unknown",
      accuracy: "unknown",
      range: "unknown"
    }

}

const generateProductionSheetData = (assignments, data) => {

  const sheets = []

  for (let i = 0; i < assignments.length; i++) {

    const a = assignments[i];

    const systemType = data.hs.products.find(v => v.hs_sku === a.baseType).name
    const setupType = data.hs.products.find(v => v.hs_sku === a.setupType)
    const setupTypeString = a.setupType !== "None" ? `${setupType.name} (${setupType.hs_sku})` : "Standard"


    sheets.push({
      systemId: a.systemId,
      type: systemType,
      setup: setupTypeString,
      scale: a.scaleType,
      ramp: a.rampType,
      siteAddress: data.hs.delivery_address,
      deal: data.hs.dealname,
      licenseType: data.licenseType,

      lft: a.baseType.slice(-1) === '5' ? "LFT" : "-",

      deadline: data.hs.ticket.internal_shipment_date,
      noDisplays: data.dangerousItems.screen,
      barcodeScanner: data.dangerousItems.barcode,
      accessories: data.dangerousItems.accessory,

      powerCable: data.hs.ticket.prod_power_plug,
      networking: data.hs.ticket.prod_networking,
      remoteSupport: data.hs.ticket.prod_remote_support_options,
      export: data.hs.ticket.prod_export_plugin,
      details: data.hs.further_notes_for_production,
    })
  }

  return sheets;
}

const generateBizerbaSheets = (assignments, data) => {

  const sheets = []

  for (let i = 0; i < assignments.length; i++) {

    const a = assignments[i];

    const scaleSpec = bizerbaScaleLookup(a.scaleType)


    const systemType = data.hs.products.find(v => v.hs_sku === a.baseType)
    const setupType = data.hs.products.find(v => v.hs_sku === a.setupType)
    const setupTypeString = a.setupType !== "None" ? `${setupType.name} (${setupType.hs_sku})` : "Standard"


    sheets.push({
      systemId: a.systemId,
      siteAddress: data.hs.delivery_address,
      vatId: data.hs.vat_id,
      projectLead: data.hs.contacts.filter(v => v.types.includes("project_lead")).map(contact => `${contact.firstname} ${contact.lastname} (${contact.email})`).join('\r\n'),
      operationsLead: data.hs.contacts.filter(v => v.types.includes("service_lead")).map(contact => `${contact.firstname} ${contact.lastname} (${contact.email})`).join('\r\n'),
      scaleModel: scaleSpec.model,
      scaleSize: scaleSpec.size,
      scaleAccuracy: scaleSpec.accuracy,
      scaleRange: scaleSpec.range,
      scaleRamp: a.rampType,
      isLft: a.baseType.slice(-1) === '5',
      installBaseSystem: `${systemType.name} (${systemType.hs_sku})`,
      installSetup: setupTypeString,
      installScaleModel: scaleSpec.model,
      shipmentDate: data.hs.ticket.internal_shipment_date,
    })
  }
  return sheets;
}

const generatePackingList = (data) => {

  const pl = []
  for (const [key, value] of Object.entries(data.packingList)) {
    pl.push({
      name: key,
      quantity: value
    })
  }

  return {
    dealName: data.hs.dealname,
    date: data.hs.ticket.internal_shipment_date,
    customer: data.hs.delivery_address,
    items: pl
  }
}

const generateLexOfficeData = (data) => {

  const mapped = lexOfficeMapValues(data.hs.incoterms)

  let introduction = ""
  const lineItems = []

  for (let j = 0; j < data.hs.lineItems.length; j++) {
    const li = data.hs.lineItems[j];

    const product = data.hs.products.find(v => v.hs_object_id === li.hs_product_id)
    if (product === undefined)
      continue;

    lineItems.push(
      {
        "type": "custom",
        "quantity": li.quantity,
        "name": product.name,
        "unitName": "Piece",
        "unitPrice": {
          "currency": "EUR",
          "netAmount": li.amount,
          "taxRatePercentage": 0
        }
      })
  }

  return {
    "archived": false,
    "voucherDate": new Date(),
    "address": {
      "name": data.hs.company.name,
      "supplement": data.hs.company.address2,
      "street": data.hs.company.address,
      "city": data.hs.company.city,
      "zip": data.hs.company.zip,
      "countryCode": "US"
    },
    "lineItems": lineItems,
    "totalPrice": {
      "currency": "EUR"
    },
    "taxConditions": {
      "taxType": "net"
    },
    "paymentConditions": {
      "paymentTermLabel": data.hs.payment_terms,
      "paymentTermDuration": 30
    },
    "shippingConditions": {
      "shippingDate": "2017-04-22T00:00:00.000+02:00",
      "shippingType": "none"
    },
    "title": "Auftragsbestätigung",
    "introduction": introduction,
    "deliveryTerms": mapped.mappedDeliveryTerms
  }
}

const ProductionSheetsPreview = (props) => {
  const [data, setData] = useState(null);
  const [notifcation, setNotification] = useState(null);
  const [assignments, setAssignments] = useState([]);
  const [assignmentError, setAssignmentError] = React.useState('Not all setup types have been assigned.');
  const [loading, setLoading] = useState(true);
  const ticketId = props.match.params.ticketId;

  const history = useHistory();

  const refreshData = React.useCallback(async () => {

    try {
      const { data } = await axios.get(Constants.API_BASE + 'api/production/generateSheetPreview/' + ticketId);
      console.log("Data processing logs", data.logs, data.data);

      const setupAssignmentData = generateAssignmentData(data.data)

      const formData = data.data;
      formData.totalNsp = setupAssignmentData.totalNsp;
      formData.totalBaseSystems = setupAssignmentData.totalBaseSystems;
      formData.dangerousItems = createDangerousItemsList(data, dangerousItemsList);
      formData.selectableSystemIds = setupAssignmentData.systems
      formData.selectableBase = setupAssignmentData.selectableBase
      formData.selectableExtensions = setupAssignmentData.selectableExtensions
      formData.selectableScales = setupAssignmentData.selectableScales
      formData.selectableRamps = setupAssignmentData.selectableRamps
      setData(formData);
      setAssignments(setupAssignmentData.initial)
    } catch (ex) {
      console.error(ex)
    }
    setLoading(false);
  }, [ticketId]);

  let setupAssignmentData = null;

  setupAssignmentData = generateAssignmentData(data, setupAssignmentData);

  const generateOuput = async () => {

    const dateString = format(parseISO(data.hs.ticket.hs_date_entered_140569549), "yyyy-MM-dd");

    const postData = {
      folderName: `${dateString} ${data.hs.dealname}`,
      productionSheets: generateProductionSheetData(assignments, data),
      bizerbaSheets: generateBizerbaSheets(assignments, data),
      packingList: generatePackingList(data),
      lexOfficeConfirmation: generateLexOfficeData(data)
    };

    try {
      console.log(postData)
      console.log(await axios.post(Constants.API_BASE + 'api/production/generateSheets', postData))

      setNotification({
        icon: "✅",
        title: "Triggered document generation",
        text: "Check Google Drive for results after approximately 30 seconds.",
        closeFunc: () => {
          setNotification(null);
          history.push('/production/createSheets')
        }
      })
    } catch (err) {
      console.error({ title: "Trigger Failed", text: err.message });
      setNotification({ icon: "🤯", title: "Triggered failed", text: "Check console or N8N for isses.", closeFunc: () => setNotification(null) })
      //setToast({ variant: 'danger', message: `Failed to trigger the production sheet creation. N8N returned with an error. Please see the browser console for more information.` })
    }
  }

  const getFieldValue = (field, label) => {
    return field ? field : <Alert variant="danger" className='my-0 p-1'>{label} is missing</Alert>;
  };

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" /><br />
      </Container>
    );
  }

  if (!data) {
    return (
      <Container>
        <Alert variant="danger">Failed to load data</Alert>
      </Container>
    );
  }

  const { hs, packingList, licenseType } = data;

  let projectLeads = [];
  let missingProjectLead = false;

  if (hs.contacts.length > 0) {
    projectLeads = hs.contacts.filter(v => v.types.includes("project_lead"))
    if (projectLeads.length === 0)
      missingProjectLead = true;
  }

  let opsLeads = [];
  let missingOpsLead = false;

  if (hs.contacts.length > 0) {
    opsLeads = hs.contacts.filter(v => v.types.includes("service_lead"))
    if (opsLeads.length === 0)
      missingOpsLead = true;
  }


  const allRequiredDataPresent = (
    hs.dealname &&
    hs.delivery_address &&
    hs.contacts &&
    hs.contacts.length > 0 &&
    hs.vat_id &&
    hs.system_id_s_ &&
    hs.ticket &&
    hs.ticket.prod_power_plug &&
    hs.ticket.prod_networking &&
    hs.ticket.prod_export_plugin &&

    missingOpsLead === false &&
    missingProjectLead === false &&
    assignmentError === ''
  );

  const refreshButtonHandler = () => {
    setLoading(true)
    refreshData()
  }

  return (
    <>
    <SubNavigation pages={productionNavStates} title={productionNavTitle} />
    <Container size="sm" className="my-3 container-sm">
      <NotificationModal data={notifcation} />
      <Row className="justify-content-center">
        <Col md={10}>

          <h2>Production Sheet Data Preview</h2>
          <Button className="my-3" onClick={refreshButtonHandler}>Refresh Data</Button>

          <h3>Deal Information</h3>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>Deal Name</td>
                <td>{getFieldValue(decode(hs.dealname), "Deal Name")}</td>
              </tr>
              <tr>
                <td>Site Address</td>
                <td>{getFieldValue(decode(hs.delivery_address), "Site Address")}</td>
              </tr>
              <tr>
                <td>Project Lead</td>
                <td>
                  {!missingProjectLead
                    ? projectLeads.map(contact => `${contact.firstname} ${contact.lastname}`).join(', ')
                    : <Alert className='my-0 p-1' variant="danger">Project Lead missing</Alert>
                  }
                </td>
              </tr>
              <tr>
                <td>Operations Lead</td>
                <td>
                  {!missingOpsLead
                    ? opsLeads.map(contact => `${contact.firstname} ${contact.lastname}`).join(', ')
                    : <Alert className='my-0 p-1' variant="danger">Operations Lead missing</Alert>
                  }
                </td>
              </tr>
              <tr>
                <td>VAT ID</td>
                <td>{getFieldValue(hs.vat_id, "VAT ID")}</td>
              </tr>
            </tbody>
          </Table>

          <h3>System Setup Assignment</h3>
          {setupAssignmentData !== null && (
            <SetupAssignment
              systemIds={data.selectableSystemIds}
              baseTypes={data.selectableBase}
              setupTypes={data.selectableExtensions}
              scaleTypes={data.selectableScales}
              rampTypes={data.selectableRamps}
              assignments={assignments}
              setAssignments={setAssignments}
              error={assignmentError}
              setError={setAssignmentError}
              totalBaseSystems={data.totalBaseSystems}
            />
          )}

          {data.dangerousItems.items.length > 0 && (
            <Alert>
              <strong>Attention!</strong> Found several items which require manual editing of the production sheets:
              <ul className='mb-0'>
                {data.dangerousItems.items.map((v, i) => <li key={"danger-" + i}>
                  <strong>{v.quantity}</strong> x <strong>{v.name}</strong> ({v.sku})
                </li>)}
              </ul>
            </Alert>
          )}

          <h3>Additional Information</h3>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>License/Investment Type</td>
                <td>{getFieldValue(licenseType, "License Type")}</td>
              </tr>
              <tr>
                <td>NSP</td>
                <td>{data.totalNsp > 0 ? (
                  <Alert className='my-0 p-1'>{data.totalNsp} extensions</Alert>
                ) : "none"}</td>
              </tr>
              <tr>
                <td>Power Plug</td>
                <td>{getFieldValue(hs.ticket.prod_power_plug, "Power Cable Information")}</td>
              </tr>
              <tr>
                <td>Internet Access</td>
                <td>{getFieldValue(hs.ticket.prod_networking, "Internet Access")}</td>
              </tr>
              <tr>
                <td>Remote Support</td>
                <td>{getFieldValue(hs.ticket.prod_remote_support_options, "Remote Support")}</td>
              </tr>
              <tr>
                <td>Export Information</td>
                <td>{getFieldValue(hs.ticket.prod_export_plugin, "Export Information")}</td>
              </tr>
              <tr>
                <td>Optional Additional Details</td>
                <td>{hs.further_notes_for_production}</td>
              </tr>
            </tbody>
          </Table>

          <h3>Packing List</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(packingList).map(item => (
                <tr key={item}>
                  <td>{item}</td>
                  <td>{packingList[item]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!allRequiredDataPresent && (
            <Alert variant="danger">Some of the required data is missing. Please define the missing values (highlighted in red) and reload the data to enable document generation.</Alert>
          )}
        </Col>
      </Row>

      <Row className="justify-content-center" >
        <Col md={5}>
          <Button onClick={refreshData}>Refresh Data</Button>

        </Col>
        <Col md={5} className='pb-5'>
          <Button disabled={!allRequiredDataPresent} className='float-end' onClick={generateOuput}>
            Generate Documents
          </Button>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default ProductionSheetsPreview;


