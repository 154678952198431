import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AnubisTable from "../../components/AnubisTable"; // Import AnubisTable

const MaintenanceRemoteConfiguration = (props) => {
  const plotData = props.data;

  const customerSet = new Set();

  plotData.remoteConfigs.forEach(x => {
    customerSet.add(x.customerName);

  });
  
  // Convert Sets to Arrays with a default entry
  const setToKeyValueArray = (set) => [
    { key: undefined, value: "" },
    ...Array.from(set).sort().map(i => ({ key: i, value: i }))
  ];
  
  const customerNames = setToKeyValueArray(customerSet);

  const columns = React.useMemo(() => [
    {
      header: "System",
      accessorKey: 'gateId',
      sort: true,
      cell: ({row, getValue}) => { return <Link to={"/systems/show/" + row.original.id}>{getValue()}</Link>},

    },
    {
      header: "Customer",
      accessorKey: 'customerName',
      cell: ({row, getValue}) => <Link to={"/customers/edit/" + row.original.customerId}>{getValue()}</Link>,
      meta: {
        filterVariant: "select",
        filterSelectValues: customerNames
      }
    },
    {
      header: "UpdateTask",
      accessorKey: 'systemUpdatetask',
      cell: ({row, getValue}) => (getValue() === 1) ? <span className="highlight-red">SYS</span> : ((row.original.customerUpdatetask === 1) ? <span className="highlight-green">CUS</span> : "-"),
      sort: true,
      enableColumnFilter: false
    },
    {
      header: "SensorService",
      accessorKey: 'systemSensorservice',
      cell: ({row, getValue}) => (getValue() === 1) ? <span className="highlight-red">SYS</span> : ((row.original.customerSensorservice === 1) ? <span className="highlight-green">CUS</span> : "-"),
      sort: true,
      enableColumnFilter: false
    },
    {
      header: "Anubis",
      accessorKey: 'systemAnubis',
      cell: ({ getValue, row }) => (getValue() === 1) ? <span className="highlight-red">SYS</span> : ((row.original.customerAnubis === 1) ? <span className="highlight-green">CUS</span> : "-"),
      sort: true,
      enableColumnFilter: false
    },
    {
      header: "MetriX1 UserPrefs",
      accessorKey: 'systemMxuserprefs',
      cell: ({ value, row }) => (value === 1) ? <span className="highlight-red">SYS</span> : ((row.original.customerMxuserprefs === 1) ? <span className="highlight-green">CUS</span> : "-"),
      sort: true,
      enableColumnFilter: false
    }
  ], [plotData]);

  return (
    <Container fluid className="mb-5">
      <Row>
        <Col className="panel">
          <h3>Remote Configurations</h3>
          <AnubisTable
            keyField="id"
            data={plotData.remoteConfigs}
            columns={columns}
            paginationEnabled={true}
            sizePerPage={15}
            classes="log-table"
            enableFilters={true}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenanceRemoteConfiguration;