import React from "react";
import axios from 'axios';
import { Col, Spinner } from "react-bootstrap";

import { AnubisTable, FormattedDate } from "../";

import * as Constants from '../../utils/constants';

const defaultShownPerPage = 50;

const getFetchParams = (systemId, newState) => {
    let params = {
        page: 0,
        count: defaultShownPerPage,
        systemId: systemId,
    };

    if (newState !== null) {
        params.page = newState.pagination.pageIndex;
    }

    return params;
};

const updateMinMax = (newState, minKey, maxKey, value) => {
    if (newState[minKey] > value) {
        newState[minKey] = value;
    }
    if (newState[maxKey] < value) {
        newState[maxKey] = value;
    }
};

const getColors = (cell, minVal, maxVal) => {
    const p = (cell - minVal) / (maxVal - minVal);
    const pct = Math.round(p * 100);
    let red = 1;
    let green = 1;
    const fac = p * 2;
    if (fac <= 1) green = fac;
    else red = 1 - (fac - 1.0);
    green = Math.round(green * 255);
    red = Math.round(red * 255);
    return { pct, green, red };
};

const fetchData = (systemId, setTableProps, newState) => {
    const params = getFetchParams(systemId, newState);

    axios.get(`${Constants.API_BASE}api/sensor`, { params: params })
        .then(res => {
            const newState = {
                page: params.page,
                data: res.data.rows,
                dataCount: 5000,

                hasHdd: false,
                hasMb1: false,
                hasMb2: false,
                hasRam: true,

                maxCpuLoad: 100,
                maxCpuClock: 3500,
                maxCpuTemp: 95,
                maxMb1Temp: 75,
                maxMb2Temp: 95,
                maxHddTemp: 75,
                maxRam: 100,

                minCpuLoad: 0,
                minCpuClock: 800,
                minCpuTemp: 45,
                minMb1Temp: 30,
                minMb2Temp: 30,
                minHddTemp: 15,
                minRam: 0
            };

            newState.data.forEach(row => {
                updateMinMax(newState, "minCpuClock", "maxCpuClock", row.cpuClock);

                if (row.hddTemp != null) {
                    newState.hasHdd = true;
                }
                if (row.mb1Temp != null) {
                    newState.hasMb1 = true;
                }
                if (row.mb2Temp != null) {
                    newState.hasMb2 = true;
                }
            });

            setTableProps(newState);
        })
        .catch(err => {
            console.log(err);
        });
};

const SensorDataList = ({
    systemId = null,
    colClassName = "",
    header = null,
    xs = 12
}) => {
    const [tableProps, setTableProps] = React.useState({
        page: 1,
        data: null,
        dataCount: 0
    });

    const fetchCallback = React.useCallback(() => {
        fetchData(systemId, setTableProps, null);
    }, [systemId]);

    React.useEffect(() => {
        fetchCallback();
    }, [fetchCallback]);

    const columns = React.useMemo(() => {
        const cols = [
            {
                header: 'Date',
                accessorKey: 'timestamp',
                cell: ({ row }) => {
                    return (
                        <FormattedDate local format="dd.MM.yy HH:mm:ss">{new Date(row.original.ts * 1000)}</FormattedDate>
                    )
                },
                meta: {
                    style: { width: "110px" }
                }
            },
            {
                header: 'Load',
                accessorKey: 'cpuLoad',
                meta: {
                    styleFunc: (cell) => {
                        const { pct, green, red } = getColors(cell, tableProps.minCpuLoad, tableProps.maxCpuLoad);
                        return { "background": `linear-gradient(90deg, rgba(${red},${green},0,1) 0%, rgba(${red},${green},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)` };
                    }
                }
            }
        ];

        if (tableProps.hasRam) cols.push({
            header: 'RAM %',
            accessorKey: 'ramUsage',
            meta: {
                styleFunc: (cell) => {
                    const { pct, green, red } = getColors(cell, tableProps.minRam, tableProps.maxRam);
                    return { "background": `linear-gradient(90deg, rgba(${green},${red},0,1) 0%, rgba(${green},${red},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)` };
                }
            }
        });

        cols.push({
            header: 'Clock',
            accessorKey: 'cpuClock',
            meta: {
                styleFunc: (cell) => {
                    const { pct, green, red } = getColors(cell, tableProps.minCpuClock, tableProps.maxCpuClock);
                    return { "background": `linear-gradient(90deg, rgba(${red},${green},0,1) 0%, rgba(${red},${green},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)` };
                }
            }
        });

        cols.push({
            header: 'CPU°',
            accessorKey: 'cpuTemp',
            meta: {
                styleFunc: (cell) => {
                    const { pct, green, red } = getColors(cell, tableProps.minCpuTemp, tableProps.maxCpuTemp);
                    return { "background": `linear-gradient(90deg, rgba(${green},${red},0,1) 0%, rgba(${green},${red},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)` };
                }
            }
        });

        if (tableProps.hasMb1) cols.push({
            header: 'MB1°',
            accessorKey: 'mb1Temp',
            meta: {
                styleFunc: (cell) => {
                    const { pct, green, red } = getColors(cell, tableProps.minMb1Temp, tableProps.maxMb1Temp);
                    return { "background": `linear-gradient(90deg, rgba(${green},${red},0,1) 0%, rgba(${green},${red},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)` };
                }
            }
        });

        if (tableProps.hasMb1) cols.push({
            header: 'MB2°',
            accessorKey: 'mb2Temp',
            meta: {
                styleFunc: (cell) => {
                    const { pct, green, red } = getColors(cell, tableProps.minMb2Temp, tableProps.maxMb2Temp);
                    return { "background": `linear-gradient(90deg, rgba(${green},${red},0,1) 0%, rgba(${green},${red},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)` };
                }
            }
        });

        if (tableProps.hasHdd) cols.push({
            header: 'HDD°',
            accessorKey: 'hddTemp',
            meta: {
                styleFunc: (cell) => {
                    const { pct, green, red } = getColors(cell, tableProps.minHddTemp, tableProps.maxHddTemp);
                    return { "background": `linear-gradient(90deg, rgba(${green},${red},0,1) 0%, rgba(${green},${red},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)` };
                }
            }
        });

        return cols;
    }, [tableProps]);

    return (
        <Col className={colClassName} xs={xs}>
            {header !== null && <h3 style={{ float: "left" }}>{header}</h3>}

            {tableProps.data === null ? (
                <Spinner animation="border" style={{ position: "relative", top: "5%", left: "50%", marginTop: "100px" }} />
            ) : (
                <>
                    <h2 style={{ marginBottom: 0, marginTop: 8, float: "left" }}>Sensor Data</h2>

                    <AnubisTable
                        data={tableProps.data}
                        columns={columns}

                        remoteFunc={(newTableState) => fetchData(systemId, setTableProps, newTableState)}
                        remoteCount={1e6}
                        remoteControlled={true}

                        paginationEnabled={true}

                        sizePerPage={defaultShownPerPage}

                        enableFilters={false}
                    />

                </>
            )}
        </Col>
    );
};

export default SensorDataList;