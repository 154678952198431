import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const SimpleCountTable = function ({
    data = null,
    title = "undefined title",
    rowKey = "some-key",
    dataKey = "cnt",
}) {

    let tableJsx = "";
    if (data === null || data.length === 0) {
        tableJsx = (
            <tr>
                <td colSpan="2" className="text-center">
                    <br />no data<br />
                    <span style={{ fontSize: "300%" }}>🎉</span>
                </td>
            </tr>
        )
    } else {
        tableJsx = data.map((v, index) => {
            return (<tr key={rowKey + index}>
                <td><Link to={"/systems/show/" + v.id}>{v.gateIdString}</Link></td>
                <td>{v[dataKey]}</td>
            </tr>)
        })
    }


    return (
        <Table size="sm" responsive>
            <thead>
                <tr>
                    <th style={{ width: '50%' }}>System</th>
                    <th>{title}</th>
                </tr>
            </thead>
            <tbody>
                {tableJsx}
            </tbody>
        </Table>
    )
};


export default SimpleCountTable;
