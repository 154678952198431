import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import * as Constants from "../../utils/constants";

// ✅ Register necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// 🔹 Transforms API data for Chart.js
const formatChartData = (data) => {
  if (!data || data.length === 0) return null;

  return {
    labels: data.map((item) => item.YEARWEEK), // X-axis labels
    datasets: [
      {
        label: "Support Cases",
        data: data.map((item) => item.cases),
        backgroundColor: Constants.PLOT_COLORS[0],
        yAxisID: "yCases",
      },
      {
        label: "Total Effort (Minutes)",
        data: data.map((item) => item.totalEffort),
        backgroundColor: Constants.PLOT_COLORS[1],
        yAxisID: "yEffort",
      },
    ],
  };
};

const MaintenanceEffortByWeekChart = ({ data, height }) => {
  if (!data || data.length === 0) {
    return <p style={{ textAlign: "center", marginTop: 20 }}>No non-measurement events occurred in the last 7 days</p>;
  }

  const chartData = formatChartData(data);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { position: "bottom" }, tooltip: { enabled: true } },
    scales: {
      x: { title: { display: true, text: "Year-Week" } },
      yCases: {
        position: "left",
        title: { display: true, text: "Support Cases" },
      },
      yEffort: {
        position: "right",
        title: { display: true, text: "Total Effort in Minutes" },
        grid: { drawOnChartArea: false }, // Prevents overlap
      },
    },
  };

  return <div style={{ height }}><Bar data={chartData} options={chartOptions} /></div>;
};

export default MaintenanceEffortByWeekChart;
