import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AnubisTable from "../../components/AnubisTable"; // Import AnubisTable
import VersionPieChart from "../graphs/VersionPieChart";

const MaintenanceRemoteVersions = (props) => {
  const plotData = props.data;

  const customerSet = new Set();
  const metrixSet = new Set();
  const updateTaskSet = new Set();
  const sensorServiceSet = new Set();
  const anubisSet = new Set();

  plotData.versionsOverview.forEach(x => {
    customerSet.add(x.customerName);
    metrixSet.add(x.versionMetrix || "none");
    updateTaskSet.add(x.versionUpdateTask || "none");
    sensorServiceSet.add(x.versionSensorService || "none");
    anubisSet.add(x.versionAnubis || "none");
  });
  
  // Convert Sets to Arrays with a default entry
  const setToKeyValueArray = (set) => [
    { key: undefined, value: "" },
    ...Array.from(set).sort().map(i => ({ key: i, value: i }))
  ];
  
  const customerNames = setToKeyValueArray(customerSet);
  const metrixVersions = setToKeyValueArray(metrixSet);
  const updateTaskVersions = setToKeyValueArray(updateTaskSet);
  const sensorServiceVersions = setToKeyValueArray(sensorServiceSet);
  const anubisVersions = setToKeyValueArray(anubisSet);

  const columns = React.useMemo(() => [
    {
      header: "System",
      accessorKey: 'gateId',
      cell: ({ row }) => <Link to={"/systems/show/" + row.original.id}>{row.original.gateId}</Link>,
      enableColumnFilter: false
    },
    {
      header: "Customer",
      accessorKey: 'customerName',
      cell: ({ row }) => <Link to={"/customers/edit/" + row.original.customerId}>{row.original.customerName}</Link>,
      meta: {
        filterVariant: "select",
        filterSelectValues: customerNames
      }
    },
    {
      header: "MetriX",
      accessorKey: 'versionMetrix',
      meta: {
        filterVariant: "select",
        filterSelectValues: metrixVersions
      }
    },
    {
      header: "UpdateTask",
      accessorKey: 'versionUpdateTask',
      meta: {
        filterVariant: "select",
        filterSelectValues: updateTaskVersions
      }
    },
    {
      header: "SensorService",
      accessorKey: 'versionSensorService',
      meta: {
        filterVariant: "select",
        filterSelectValues: sensorServiceVersions
      }
    },
    {
      header: "Anubis",
      accessorKey: 'versionAnubis',
      meta: {
        filterVariant: "select",
        filterSelectValues: anubisVersions
      }
    }
  ], [plotData]);

  return (
    <Container fluid className="mb-5">
      <Row>
        <Col xs={12} sm={6} className="panel">
          <h3>Deployed MetriX Versions</h3>
          <VersionPieChart dataAll={plotData?.softwareVersions.versionsMetrix} data30={plotData?.softwareVersions.versionsMetrix90} height={300} />
        </Col>
        <Col xs={12} sm={6} className="panel">
          <h3>Deployed Anubis Versions</h3>
          <div>
            <VersionPieChart dataAll={plotData?.softwareVersions.versionsAnubis} data30={plotData?.softwareVersions.versionsAnubis90} xkey="versionAnubis" height={300} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="panel">
          <h3>Versions by System</h3>
          <p style={{ paddingLeft: 4 }}>The table lists the versions for all systems that have the <b>active</b> flag enabled, and which have reported in at least once in the last 30 days.</p>
          <AnubisTable
            columns={columns}
            data={plotData.versionsOverview}
            paginationEnabled={true}
            sizePerPage={15}
            classes="log-table"
            enableFilters={true}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenanceRemoteVersions;
