import axios from 'axios';
import React from "react";
import { Container, Row, Col, Button, Table } from 'react-bootstrap'
import { AddPersonModal, EditPersonModal, ConfigComponent, LocationModal } from '../../components'
import { CustomerFormComponent } from '../../components/customers';

import BinaryDescisionModal from "../../components/BinaryDescisionModal";
import * as Constants from '../../utils/constants';
import NotificationModal from "../../components/NotificationModal";
import useModal from "../../utils/useModal";
import { Helmet } from 'react-helmet';


const CustomerUpdate = function (props) {

    const cId = props?.match?.params?.id;
    const { data: notificationData, setData: setNotificationData } = useModal();
    const [data, setData] = React.useState(null);
    const [locations, setLocations] = React.useState([]);
    const [addPersonShow, setAddPersonShow] = React.useState(false);
    const [editPersonShow, setEditPersonShow] = React.useState(null);
    const [showDescisionModal, setShowDescisionModal] = React.useState(null);
    const [locationModalShow, setLocationModalShow] = React.useState(null);

    const getLocations = () => {
        axios.get(Constants.API_BASE + 'api/location?CustomerId=' + cId)
            .then(res => {
                setLocations(res.data);
            }).catch(err => console.error(err))

    }

    const queryCustomer = () => {
        try {
            axios.get(Constants.API_BASE + 'api/customer/' + cId + "?withContacts=1")
                .then(res => setData(res.data))
        } catch (err) {
            console.error(err)
        }
    }

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/customer/' + cId + "?withContacts=1")
            .then(res => {
                setData(res.data);
            }).catch(err => console.error(err))

        axios.get(Constants.API_BASE + 'api/location?CustomerId=' + cId)
            .then(res => {
                setLocations(res.data);
            }).catch(err => console.error(err))
    }, [cId])



    if (!cId) {
        console.error("Customer ID is not set.")
        return "customer id is not set";
    }

    if (data === null) {
        return "loading...";
    }

    const defaultData = {
        name: data.name,
        notificationLevel: data.notificationLevel,
        comment: data.comment
    }

    const saveHandler = (formData) => {

        axios.put(Constants.API_BASE + 'api/customer/' + cId, formData)
            .then(res => {
                props.history.push({
                    pathname: '/customers',
                    state: { toast: `${formData.name} saved.` }
                });
            }).catch(err => setNotificationData({ title: "Failed", text: err.message }))
    }

    const removeContactHandler = async () => {
        try {
            await axios.delete(Constants.API_BASE + 'api/customer/' + cId + '/contact/' + showDescisionModal.id);
            await queryCustomer()
        }
        catch (err) {
            setNotificationData({ title: "Failed", text: err.message });
        }
        setShowDescisionModal(null)
    }

    let allowedConfigs = [
        { scope: "customer", type: "updatetask" },
        { scope: "customer", type: "sensorservice" },
        //{scope: "customer", type: "anubis"}, 
        { scope: "customer", type: "mxuserprefs" },
        //{scope: "customer", type: "mailexport"},
    ];

    return (
        <Container className="mt-3">
            <Helmet>
                <title>{data === null ? "Loading" : data.name} - Anubis</title>
            </Helmet>
            <NotificationModal data={notificationData} />

            <LocationModal show={locationModalShow !== null} customer={cId} data={locationModalShow} onHide={() => setLocationModalShow(null)} onChange={getLocations} />
            <AddPersonModal show={addPersonShow !== false} target="customer" id={cId} onHide={() => setAddPersonShow(false)} onChange={() => { queryCustomer(); setAddPersonShow(false) }} />
            <EditPersonModal data={editPersonShow} show={editPersonShow !== null} onHide={() => setEditPersonShow(null)} onChange={() => { queryCustomer(); setEditPersonShow(null) }} />
            <BinaryDescisionModal
                show={showDescisionModal !== null}
                title="Remove Contact"
                text={"Would you like to remove " + showDescisionModal?.name + " from the list of associated persons for this system?"}
                yes={() => removeContactHandler()}
                no={() => setShowDescisionModal(null)}
            />


            <CustomerFormComponent default={defaultData} saveHandler={saveHandler} />

            <Row>
                <Col>
                    <h3 className="text-center mt-3">Customer's Locations</h3>
                    <p className="text-center">
                        All customer locations are listed below. Add new locations if required. Locations are assigned to a single customer and are used for example for automatic usage and system health evaluation.
                    </p>
                    <div className="text-center">
                        {locations.map(v => {

                            const locode = (v.locode === null) ? "(unknown)" : `(${v.locode})`

                            return <Button key={'loc-' + v.id} className="me-1 mb-2" variant="outline-primary" onClick={() => setLocationModalShow({ name: v.name, locode: v.locode, id: v.id })}>{v.name} {locode}</Button>
                        })}

                        <Button variant="primary" className="mb-2" onClick={() => setLocationModalShow({})}>Add Location</Button>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3 className="text-center mt-3">Related Persons</h3>
                    <p className="text-center">
                        The following persons are associated with this customer. All the customer's locations inherit these persons.
                    </p>
                    <Table size="sm" responsive>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>E-Mail</th>
                                <th>Phone</th>
                                <th>Description</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data !== null && data.Contacts.map((v, index) => {
                                return (
                                    <tr key={"contact" + index}>
                                        <td>{v.name}</td>
                                        <td>{v.email}</td>
                                        <td>{v.phone}</td>
                                        <td>{v.description}</td>
                                        <td>
                                            <Button className="float-end" size="sm" type="submit" onClick={() => setShowDescisionModal(v)}>Remove</Button>
                                            <Button className="float-end me-1" size="sm" type="submit" onClick={() => setEditPersonShow(v)}>Edit</Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <Button variant="primary" className="float-end" size="sm" onClick={() => setAddPersonShow(true)}>Add Contact</Button>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3 className="text-center mt-3">Configurations</h3>
                </Col>
            </Row>
            <ConfigComponent id={cId} parentType="customer" allowedConfigs={allowedConfigs} />
        </Container>
    );
};

export default CustomerUpdate;
