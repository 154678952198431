import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import * as Constants from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfinity, faCalendar } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import TooltipComponent from "react-bootstrap/Tooltip";

ChartJS.register(ArcElement, Tooltip, Legend);

const VersionPieChart = ({ height = 350, xkey = "versionMetrix", ykey = "c", dataAll = null, data30 = null }) => {
  const [useAllData, setUseAllData] = React.useState(false);

  const plotData = useAllData ? dataAll : data30;
  const labels = plotData?.map((item) => (item[xkey] == null ? "Unknown" : item[xkey])) || [];
  const dataValues = plotData?.map((item) => item[ykey]) || [];

  const chartData = {
    labels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: Constants.PLOT_COLORS,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "bottom" },
      tooltip: { enabled: true },
    },
  };

  return (
    <div style={{ display: "block" }}>
      <div style={{ display: "block", zIndex: 10, float: "left", padding: 5, borderRight: "2px solid #d1d4d5", textAlign: "center" }}>
        <OverlayTrigger overlay={<TooltipComponent>Analyze 30 days</TooltipComponent>} placement="right">
          <FontAwesomeIcon
            icon={faCalendar}
            className="nav-button"
            size="lg"
            style={{ marginBottom: 5, paddingRight: 2, color: useAllData ? Constants.COLORS.FONT : Constants.COLORS.FONT_HIGHLIGHT }}
            onClick={() => setUseAllData(false)}
          />
        </OverlayTrigger>
        <br />
        <OverlayTrigger overlay={<TooltipComponent>Analyze all data</TooltipComponent>} placement="right">
          <FontAwesomeIcon
            icon={faInfinity}
            className="nav-button"
            size="lg"
            style={{ paddingRight: 2, color: useAllData ? Constants.COLORS.FONT_HIGHLIGHT : Constants.COLORS.FONT }}
            onClick={() => setUseAllData(true)}
          />
        </OverlayTrigger>
      </div>
      <div style={{ height: height }}>
        <Doughnut data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default VersionPieChart;
