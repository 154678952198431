import React from "react";
import { Button } from "react-bootstrap";

const StatefulSubNavigation = function ({
    title = "MAINTENANCE",
    view = null,
    setView = () => { },
    pages = []
}) {

    return (
        <div style={styles.container}>
            <span style={styles.title}>{title}</span>

            {pages.map((v, i) => {
                return <Button key={'subnav'+i} variant={view === v.key ? "secondary" : "light"} onClick={() => setView(v.key)}>{v.title}</Button>
            })}
        </div>
    )
};

const styles = {
    container: {
        backgroundColor: "#b3b8b9",
        paddingTop: 2,
        paddingBottom: 4,
        flex: 1,
        display: "flex",
        gap: 4,
        alignContent: 'center',
        justifyContent: 'left',
    },
    title: {
        paddingLeft: 10,
        paddingTop: 5,
        paddingRight: 6,
    }

}

export default StatefulSubNavigation;
