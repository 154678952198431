import React from "react";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { Table } from "react-bootstrap";

const AnubisTable = (
    {
        columns = null,
        data = null,
        sizePerPage = 25,
        paginationEnabled = false,
        enableFilters = false,
        remoteFunc = null,
        remoteControlled = false,
        remoteCount = undefined
    }) => {

    // TODO: remote sorting
    const [columnFilters, setColumnFilters] = React.useState([]);

    const [pagination, setPagination] = React.useState({
        pageIndex: 0, //initial page index
        pageSize: sizePerPage, //default page size
    });

    React.useEffect(() => {
        if (remoteFunc !== null) {
            remoteFunc({
                pagination: pagination,
                filters: columnFilters
            })
        }
    }, [pagination, columnFilters]);


    const table = useReactTable({
        data,
        columns,
        filterFns: {},
        state: {
            columnFilters,
            pagination: pagination
        },

        pageCount: (remoteCount === undefined) ? undefined : Math.ceil(remoteCount / sizePerPage),
        
        manualFiltering: remoteControlled,
        manualPagination: remoteControlled,
        manualSorting: remoteControlled,

        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
        
        enableFilters: enableFilters
    });


    if (data === null) return <div>Loading data for table..</div>;

    return (
        <>
            <Table className="log-table" style={{ tableLayout: "fixed" }}>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th key={header.id} colSpan={header.colSpan} align={(header.column.columnDef.meta)?.align} style={(header.column.columnDef.meta)?.style}>
                                        {header.isPlaceholder ? null : (
                                            <>
                                                <div
                                                    {...{
                                                        className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {{ asc: ' 🔼', desc: ' 🔽', }[header.column.getIsSorted()] ?? null}
                                                </div>
                                                {header.column.getCanFilter() ? (
                                                    <div>
                                                        <Filter column={header.column} />
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {

                                    let style = undefined;

                                    if (cell.column.columnDef.meta?.styleFunc)
                                        style = cell.column.columnDef.meta.styleFunc(cell.renderValue())

                                    return (
                                        <td key={cell.id} align={(cell.column.columnDef.meta)?.align} style={style}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            {(paginationEnabled === true && (
                <div className="text-center">
                    <button
                        className="border btn btn-primary p-1 mx-1"
                        onClick={() => table.firstPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<<'}
                    </button>
                    <button
                        className="border btn btn-primary p-1 mx-1"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<'}
                    </button>
                    <button
                        className="border btn btn-primary p-1 mx-1"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>'}
                    </button>
                    <button
                        className="border btn btn-primary p-1 mx-1"
                        onClick={() => table.lastPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>>'}
                    </button>
                    <div className="text-center mb-2">
                            {table.getState().pagination.pageIndex + 1} of{' '}
                            {table.getPageCount().toLocaleString()}
                    </div>
                </div>
            ))}
        </>
    );
};

function Filter({ column }) {
    const columnFilterValue = column.getFilterValue();
    const { filterVariant } = column.columnDef.meta ?? {};

    let selectVals = column.columnDef?.meta?.filterSelectValues;
    if (!selectVals)
        selectVals = [
            { key: "", value: "empty" }
        ]

    return filterVariant === 'range' ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput
                    type="number"
                    value={(columnFilterValue)?.[0] ?? ''}
                    onChange={(value) =>
                        column.setFilterValue((old) => [value, old?.[1]])
                    }
                    placeholder={`Min`}
                    className="w-24 border"
                />
                <DebouncedInput
                    type="number"
                    value={(columnFilterValue)?.[1] ?? ''}
                    onChange={(value) =>
                        column.setFilterValue((old) => [old?.[0], value])
                    }
                    placeholder={`Max`}
                    className="w-24 border"
                />
            </div>
            <div className="h-1" />
        </div>
    ) : filterVariant === 'select' ? (
        <select onChange={(e) => column.setFilterValue(e.target.value)} value={columnFilterValue?.toString()}>
            {
                selectVals.map((v, i) => <option key={`${column.columnDef.accessorKey}-filter-${i}`} value={v.key}>{v.value}</option>)
            }
        </select>
    ) : (
        <DebouncedInput
            className="w-36 border"
            onChange={(value) => column.setFilterValue(value)}
            placeholder={`Search...`}
            type="text"
            value={(columnFilterValue ?? '')}
        />
    );
}

function DebouncedInput({ value: initialValue, onChange, debounce = 500, ...props }) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <input
            {...props}
            value={value}
            onChange={(e) => setValue(e.target.value)}
        />
    );
}

export default AnubisTable;