import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

import { AnubisTable } from "../";

import SimpleCountTable from "./SimpleCountTable";
import TemporalDeltaTable from "./TemporalDeltaTable";

const MaintenanceHealthMetrics = (props) => {
  const plotData = props.data;

  console.log(props.data)

  const columns = React.useMemo(() => [
    {
      header: "Serial",
      accessorKey: 'serial',
      sort: true,
      cell: ({ row }) => <>{row.original.serial} ({row.original.cameraType})</>,
    },
    {
      header: "System ID",
      accessorKey: 'gateIdString',
      cell: ({ row }) => <Link to={"/systems/show/" + row.original.GateId}>{row.original.gateIdString}</Link>,
      sort: true,
    },
    {
      header: "Start ROI Miss. %",
      accessorKey: 'roiStart',
      cell: ({ row }) => (row.original.roiStart === null) ? 'null' : row.original.roiStart.toFixed(2),
      sort: true,
      className: 'numeric',
    },
    {
      header: "End ROI Miss. %",
      accessorKey: 'roiEnd',
      cell: ({ row }) => (row.original.roiEnd === null) ? 'null' : row.original.roiEnd.toFixed(2),
      sort: true,
      className: 'numeric',
    },
    {
      header: "abs(Δ) ROI Start",
      accessorKey: 'roiDelta',
      cell: ({ row }) => (row.original.roiDelta === null) ? 'null' : row.original.roiDelta.toFixed(2),
      sort: true,
      className: 'numeric-contrast',
    },
    {
      header: "Start °",
      accessorKey: 'angleStart',
      cell: ({ row }) => (row.original.angleStart === null) ? 'null' : row.original.angleStart.toFixed(2),
      sort: true,
      className: 'numeric',
    },
    {
      header: "End °",
      accessorKey: 'angleEnd',
      cell: ({ row }) => (row.original.angleEnd === null) ? 'null' : row.original.angleEnd.toFixed(2),
      sort: true,
      className: 'numeric',
    },
    {
      header: "abs(Δ) ° Start",
      accessorKey: 'angleDelta',
      cell: ({ row }) => (row.original.angleDelta === null) ? 'null' : row.original.angleDelta.toFixed(2),
      sort: true,
      className: 'numeric-contrast',
    },
  ], []);

  return (
    <Container fluid className="mb-5">
      <Row >
        <Col xs={12} sm={3} className="panel">
          <h3>Calibrations last 28 days
            <OverlayTrigger overlay={<Tooltip>Number of uploaded calibrations in the last 28 days</Tooltip>} placement="bottom">
              <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginBottom: "0px", marginLeft: "8px" }} /></span>
            </OverlayTrigger>
          </h3>
          <div style={{ maxHeight: "300px", minHeight: "300px", overflowY: "scroll" }}>
            <SimpleCountTable title="# Calibrations" data={plotData.calibrations28Days} rowKey="failedCalibs" />
          </div>
        </Col>

        <Col xs={12} sm={3} className="panel">
          <h3>Weekly Failed Calibrations
            <OverlayTrigger overlay={<Tooltip>Number of failed calibrations in the last 7 days</Tooltip>} placement="bottom">
              <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginLeft: "8px" }} /></span>
            </OverlayTrigger>
          </h3>
          <div style={{ maxHeight: "300px", minHeight: "300px", overflowY: "scroll" }}>
            <SimpleCountTable title="Failed Calibrations" data={plotData.failedCalibs7Days} rowKey="failedCalibs" />
          </div>
        </Col>

        <Col xs={12} sm={3} className="panel">
          <h3>Weekly Lost local Cameras
            <OverlayTrigger overlay={<Tooltip>Number of lost connections to the local camera in the last 7 days</Tooltip>} placement="bottom">
              <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginLeft: "8px" }} /></span>
            </OverlayTrigger>
          </h3>
          <div style={{ maxHeight: "300px", minHeight: "300px", overflowY: "scroll" }}>
            <SimpleCountTable title="Lost Local" data={plotData.lostLocal7Days} rowKey="lostLocal" />
          </div>
        </Col>

        <Col xs={12} sm={3} className="panel">
          <h3>Weekly Lost remote Cameras
            <OverlayTrigger overlay={<Tooltip>Number of lost connections to the remote camera in the last 7 days</Tooltip>} placement="bottom">
              <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginLeft: "8px" }} /></span>
            </OverlayTrigger>
          </h3>
          <div style={{ maxHeight: "300px", minHeight: "300px", overflowY: "scroll" }}>
            <SimpleCountTable title="Lost Remote" data={plotData.lostRemote7Days} rowKey="lostRemote" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="panel">
          <h3>Calibration Health Metrics
            <OverlayTrigger overlay={<Tooltip>Δ's are computed by subtracting from the 'start' or 'interval' value from the 'end'. The interval value is calculated from the most recent values with a minimum age of 30 days. Values are averaged (5 samples).</Tooltip>} placement="bottom">
              <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginLeft: "8px" }} /></span>
            </OverlayTrigger>
          </h3>
          <AnubisTable
            keyField="serial"
            data={plotData.calibrationsData}
            columns={columns}
            paginationEnabled={true}
            sizePerPage={15}
            classes="log-table"
          />
        </Col>
      </Row>
      <Row >
        <Col xs={12} sm={4} className="panel">
          <h3>Temporal Drift between Logserver and System</h3>
          <div style={{ maxHeight: "300px", minHeight: "350px", overflowY: "scroll" }}>
            <TemporalDeltaTable data={plotData.temporalDeltas} rowKey="deltaMinutes" />
          </div>
        </Col>
        <Col xs={12} sm={8}>
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenanceHealthMetrics;
